import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { PagesModule } from './pages/pages.module';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { CognitoUtil } from './services/aws/cognito.service';
// import { AwsUtil } from './service/aws/aws.service';
import { UserRegistrationService } from './services/aws/user-registration.service';
import { UserLoginService } from './services/aws/user-login.service';
import { UserParametersService } from './services/aws/user-parameters.service';
//import { FlowService } from './services/flow.service';
//import { DynamoCRUDService } from './services/dynamo-crud.service';
import { SearchService } from './services/search.service'
import { ConversationService } from './services/conversation.service'
import {SaveServices} from './services/save.services'

import {LoginServices} from './services/login.services'
import {UploadService} from './services/upload.service'
import { CustomerSevice } from './services/customer.service'
import { AuthGuard, AntiAuthGuard, UniversalUser, DataSharingService } from './services/shared.service'

import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    PagesModule,
    FormsModule,
    HttpClientModule,
    routing,
    NgxSpinnerModule,
	ModalModule.forRoot(),
  ],
  providers: [
    CognitoUtil,
    // AwsUtil,
    UserRegistrationService,
    UserLoginService,
    UserParametersService,
    SearchService, 
    ConversationService,
    SaveServices,
    DatePipe,
    LoginServices,
    UploadService,
    AuthGuard,
    AntiAuthGuard, 
    UniversalUser, 
    CustomerSevice,
    DataSharingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
