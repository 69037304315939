var Payment = /** @class */ (function () {
    function Payment() {
        this.paymentDetails = {};
        this.functionInstanceName = "CREATE_ORDER";
        this.paymentAmount = null;
        this.paymentStatus = null;
        this.paidAmount = null;
        this.paymentChannel = null;
        this.transactionNumber = null;
        this.paymentId = null;
        this.paymentRequestId = null;
        this.paidStatus = null;
        this.purpose = null;
    }
    return Payment;
}());
export { Payment };
var RazorPayOrder = /** @class */ (function () {
    function RazorPayOrder() {
        this.amount = 0;
        this.currency = null;
        this.receipt = null;
        this.payment_capture = null;
        this.notes = {};
    }
    return RazorPayOrder;
}());
export { RazorPayOrder };
