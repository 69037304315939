export class FileUploadData {
    userFileName: string;
    documentName: string;
    formData: FormData;
    documentType: string;
    errorMsg: string;
 

    constructor(userFileName?: string, documentName?: string, formData?: FormData, documentType?: string, errorMsg?: string) {
        this.userFileName = userFileName ? userFileName : null;
        this.documentName = documentName ? documentName : null;
        this.formData = formData ? formData : null;
        this.documentType = documentType ? documentType : null;
        this.errorMsg = errorMsg ? errorMsg : null;
    }
}