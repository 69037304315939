import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SuccessResponse } from '../models/success.model'
import {SendOtp} from '../models/sendotp.model'
import {VerifyOtp} from '../models/verifyotp.model'
import { Claim } from '../models/claim.model';
import { Customer } from '../models/customer.model';

@Injectable()
export class CustomerSevice {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json','Access-Control-Allow-Origin':'*'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    getCustomersByPhoneNumber(phoneNumber: string): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.claimcustomer}`;
        const body = {
            key: {
                phoneNumber: phoneNumber
            },
            operation: "GET_ALL"
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getClaimsByImei(imei: string, policyId: string, clearCache?: boolean): Observable<Claim[]> {
        const subject = new Subject<Claim[]>();
        const url = `${environment.apiurl + environment.claims}`;
        const body = { "imei" : imei, "companyContext": { "companyId": environment.companyId }, clearCache: clearCache };
        if (policyId) {
            body["policyId"] = [policyId];
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Claim[]>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    uploadDocument(formData: FormData) {
        const headers = new HttpHeaders({'X-Auth-Token' : environment.companyId});
        const url = `${environment.apiurl + environment.uploadUrl}`;
        if (formData) {
        return this.httpClient.post<any>(
            url,
            formData,
            {
            headers: headers,
            observe: 'body',
            reportProgress: true
            }
        );
        }
    }

    updateClaimCustomer(customer: Customer, updateFlow?: boolean): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.claimcustomer}`;
        const body = {
            value: customer,
            operation: "UPDATE",
            entityId: customer.entityId
        }
        if (updateFlow) {
            body["updateFlow"] = updateFlow;
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    updateClaim(imei?: string, claimToBeUpdated?: Claim): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.updateClaim}`;
        const body = {
            imei: imei,
            values: claimToBeUpdated,
            id: claimToBeUpdated.id
        }
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )        
        return subject.asObservable();
    }



}
