import { v4 as uuid } from 'uuid';
import { OnInit, TemplateRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { VerifyOtp } from '../../models/verifyotp.model';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { UploadService } from "../../services/upload.service";
import { SaveServices } from "../../services/save.services";
import { DataSharingService, UniversalUser } from '../../services/shared.service';
import { CustomerSevice } from '../../services/customer.service';
import { Documents } from '../../models/documents.model';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from '../../models/profile.model';
import { FileUploadData } from '../../models/fileupload.model';
import { RazorPayOrder } from '../../models/payment.model';
import { BsModalService } from 'ngx-bootstrap/modal';
var CustomerComponent = /** @class */ (function () {
    function CustomerComponent(router, route, uploadService, spinner, saveService, dataSharingService, universalUser, customerService, modalService, changeDetectorRef) {
        this.router = router;
        this.route = route;
        this.uploadService = uploadService;
        this.spinner = spinner;
        this.saveService = saveService;
        this.dataSharingService = dataSharingService;
        this.universalUser = universalUser;
        this.customerService = customerService;
        this.modalService = modalService;
        this.changeDetectorRef = changeDetectorRef;
        this.selectedClaimNumber = null;
        this.phoneNumber = null;
        this.payModel = new Profile();
        this.errorMessage = null;
        this.prevErrorMsg = null;
        this.showPayment = false;
        this.isPickupAddressSameAsDispatchAddress = false;
        this.addressSubmitted = false;
        this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP = "HDFC Payment Slip";
        this.isPreviousBtnEnabled = false;
        this.isNextBtnEnabled = true;
        this.isSubmitBtnEnabled = false;
        this.isDocumentSubmitBtnEnabled = false;
        this.showModal = false;
        this.showFailedModal = false;
        this.showDocumentModal = false;
        this.passwordFormatted = null;
        this.nextDisable = false;
        this.paymentStatus = "";
        this.verifyOtp = new VerifyOtp();
        this.selectedCustomer = null;
        this.selectedClaim = null;
        this.customers = [];
        this.customersFiltered = [];
        this.tempDocument = null;
        this.fileUploadData = null;
        this.previousDocuments = [];
        this.nextDocumnets = [];
        this.claimDocument = [];
        this.uploadFileDocument = [];
        this.uploadFileTempDocument = [];
        this.submitDocuments = [];
        this.lastDocument = null;
        this.wizardselectedCnt = 1;
        this.paymentMethodOptions = [
            {
                key: "PAY_ONLINE",
                value: "Pay Online using Debit/ Credit card/ Net Banking/ UPI"
            },
            {
                key: "PAY_AT_HDFC",
                value: "Pay at HDFC Bank Branch"
            }
        ];
        this.stateOptions = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman & Diu', 'Delhi', 'Goa', 'Gujarat ', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Orissa', 'Pondicherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'];
        this.paymentDocument = null;
    }
    CustomerComponent.prototype.ngOnInit = function () {
        //console.log(this.route.snapshot);
        //console.log("*********** shared object **************");
        //console.log(this.dataSharingService.getSharedObject());
        //this.showPaymentSuccessModal(); 
        this.paymentId = this.route.snapshot.queryParams["payment_id"];
        this.paymentStatus = this.route.snapshot.queryParams["payment_status"];
        this.paymentRequestId = this.route.snapshot.queryParams["payment_request_id"];
        this.phoneNumber = this.route.snapshot.params["phoneNumber"];
        this.selectedClaimNumber = this.route.snapshot.params["claimNumber"];
        //console.log("payment status :: " + this.paymentStatus);
        /*
        if (this.paymentStatus ) {
          console.log("user");
            console.log(this.universalUser.getUser());
            var phoneNumber: string = null;
            if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
              phoneNumber = this.universalUser.getUser().phoneNumber;
            }
            else {
              phoneNumber = this.phoneNumber;
            }
            if (this.paymentStatus == "Failed") {
              this.errorMessage = "Paymnet transaction failed. Please try again later"
              //this.getCustomersByPhoneNumber(phoneNumber);
          this.showPaymentFailedModal();
            }
            if (this.paymentStatus == "Credit") {
              //this.getCustomersByPhoneNumber(phoneNumber, true);
          this.showPaymentSuccessModal();
            }
        }*/
        if (this.paymentStatus) {
            //console.log("user");
            //console.log(this.universalUser.getUser());
            var phoneNumber = null;
            if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
                phoneNumber = this.universalUser.getUser().phoneNumber;
            }
            else {
                phoneNumber = this.phoneNumber;
            }
            if (this.paymentStatus == "Failed") {
                this.errorMessage = "Payment transaction failed. Please try again later";
                this.getCustomersByPhoneNumber(phoneNumber);
            }
            if (this.paymentStatus == "Credit") {
                this.getCustomersByPhoneNumber(phoneNumber, true);
            }
        }
        this.customers = JSON.parse(JSON.stringify(this.dataSharingService.getSharedObject()));
        if (this.customers != null && this.customers.length > 0) {
            //this.verifyOtp.customer = this.customers[0];
            this.setCustomer();
        }
        else {
            var user = this.universalUser.getUser();
            if (user != null) {
                // get customers from phone number
                this.getCustomersByPhoneNumber(user.phoneNumber);
            }
        }
        //   if (this.paymentStatus != null && this.paymentStatus =='Credit') {
        //    console.log(this.payment);
        //    this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
        //    this.savepayment();
        //   }
        //   else{
        //   console.log('--else-init---')
        //   console.log(this.verifyOtp);
        //   //this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
        //   console.log('2 init---')
        //   console.log(this.verifyOtp);
        //   if (this.verifyOtp==null){
        //     //this.router.navigate(['/pg/login']);
        //   }
        //   else{
        //     for (let pay of this.verifyOtp.customer.payments)
        //     {
        //       if (pay.paymentStatus == 'Credit'){
        //         this.paymentStatus='Credit';
        //       }
        //     }
        //     //console.log(this.verifyOtp.claim.applicableFee);
        //   }
        // }
    };
    CustomerComponent.prototype.ngOnDestroy = function () {
        //sessionStorage.clear();
    };
    CustomerComponent.prototype.unloadHandler = function (event) {
        //window.sessionStorage.clear();
    };
    CustomerComponent.prototype.getCustomersByPhoneNumber = function (phoneNumber, updatePaymnet) {
        var _this = this;
        this.customerService.getCustomersByPhoneNumber(phoneNumber)
            .subscribe(function (response) {
            console.log("getCustomersByPhoneNumber");
            console.log(response);
            if (response && response.success && response.result) {
                _this.customers = response.result;
                _this.customersFiltered = _this.customers.filter(function (claimItem) {
                    if (claimItem.hasOwnProperty('statusCd')) {
                        return claimItem.statusCd == "ACTIVE";
                    }
                });
                if (_this.customersFiltered && _this.customersFiltered.length == 1) {
                    _this.selectedCustomer = _this.customers[0];
                    _this.selectedClaimNumber = _this.selectedCustomer.claimNumber;
                    _this.getClaimsByImei();
                }
                console.log(_this.customersFiltered);
                if (updatePaymnet) {
                    _this.setCustomer(updatePaymnet);
                    _this.showPaymentSuccessModal();
                }
            }
        }, function (error) {
            console.log("getCustomersByPhoneNumber error");
            console.log(error);
        });
    };
    CustomerComponent.prototype.setCustomer = function (updatePayment) {
        console.log("setCustomer");
        console.log(this.customers);
        this.customersFiltered = this.customers.filter(function (claimItem) {
            if (claimItem.hasOwnProperty('statusCd')) {
                return claimItem.statusCd == "ACTIVE";
            }
        });
        if (this.customersFiltered && this.customersFiltered.length == 1) {
            this.selectedCustomer = this.customersFiltered[0];
            this.selectedClaimNumber = this.selectedCustomer.claimNumber;
            this.getClaimsByImei();
        }
        if (this.customers && this.customers.length == 1 && this.customers[0].statusCd == "ACTIVE") {
            this.selectedCustomer = this.customers[0];
            this.selectedClaimNumber = this.selectedCustomer.claimNumber;
            this.getClaimsByImei();
        }
        else if (this.customers && this.selectedClaimNumber) {
            var indexc = this.selectedClaimNumber.indexOf("t");
            if (indexc != -1) {
                this.selectedClaimNumber = this.selectedClaimNumber.substring(0, indexc);
            }
            //console.log("selected claim number");
            //console.log(this.selectedClaimNumber);
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var cust = _a[_i];
                //console.log(cust.claimNumber);
                if (cust.claimNumber == this.selectedClaimNumber) {
                    // const index = this.selectedCustomer.claimNumber.indexOf("t");
                    // if (index != -1) {
                    //   this.selectedCustomer.claimNumber = this.selectedCustomer.claimNumber.substring(0, index);
                    // }
                    this.selectedCustomer = cust;
                }
            }
        }
        if (this.selectedCustomer) {
            this.setDocumentsToBeProcess();
        }
        if (updatePayment) {
            //console.log("selected claim")
            //this.getClaimsByImei();
            this.updateCustomerWithPayment();
        }
    };
    CustomerComponent.prototype.updateCustomerWithPayment = function () {
        if (this.selectedCustomer.payments) {
            var payments = [];
            this.spinner.show();
            for (var _i = 0, _a = this.selectedCustomer.payments; _i < _a.length; _i++) {
                var paymnet = _a[_i];
                if (this.paymentStatus == "Credit") {
                    paymnet.paymentStatus = "PAID";
                    paymnet.paymentId = this.paymentId;
                    paymnet.transactionNumber = this.paymentRequestId;
                    paymnet.paymentChannel = "PAY_ONLINE";
                    //if (this.selectedClaim == null) {
                    //}
                }
                if (this.paymentStatus == "Failed") {
                    paymnet.paymentStatus = "FAILEd";
                }
                payments.push(paymnet);
            }
            this.selectedCustomer.payments = payments;
        }
        this.showPayment = true;
        this.tempDocument = null;
        this.updateCustomer(true);
    };
    CustomerComponent.prototype.paynow = function (fee) {
        var _this = this;
        if (fee < 9) {
            fee = 10;
        }
        this.payModel.amount = fee;
        this.payModel.phone = this.selectedCustomer.phoneNumber;
        this.payModel.email = this.selectedClaim.emailId ? this.selectedClaim.emailId : "sanket@automatapi.com";
        if (window.location.href.indexOf("hi") != -1) {
            this.payModel.redirectUrl = environment.hi_RETURN_URL + "/" + this.selectedCustomer.phoneNumber + "/" + this.selectedClaimNumber;
        }
        else {
            this.payModel.redirectUrl = environment.RETURN_URL + "/" + this.selectedCustomer.phoneNumber + "/" + this.selectedClaimNumber;
        }
        var purpose = "";
        if (this.selectedCustomer.imei) {
            purpose = this.selectedCustomer.imei;
        }
        if (!purpose) {
            purpose = this.selectedCustomer.phoneNumber;
        }
        if (this.selectedClaimNumber) {
            purpose = purpose + "-" + this.selectedClaimNumber;
        }
        this.payModel.purpose = purpose;
        //console.log('Applicable fees to be paid');
        //console.log(fee);
        this.saveService.payNow(this.payModel)
            .subscribe(function (response) {
            //console.log("paynow response");
            //console.log(response);
            if (response.success && response.result && response.result.payment_request && response.result.payment_request.longurl) {
                window.location.href = response.result.payment_request.longurl + "";
            }
            else {
                _this.errorMessage = "Something went wrong";
            }
            //window.location.href = response+"";
            // window.open(response+"", "_blank");
        }, function (error) {
            console.log("error");
        });
    };
    CustomerComponent.prototype.razorPaymentOrder = function (fee, doNothing) {
        var _this = this;
        if (doNothing) {
            return;
        }
        this.spinner.show();
        var razorPayOrder = new RazorPayOrder();
        razorPayOrder.amount = fee * 100;
        razorPayOrder.currency = "INR";
        razorPayOrder.payment_capture = "1";
        razorPayOrder.receipt = this.selectedCustomer.claimNumber;
        razorPayOrder.notes = {
            "claimNuber": this.selectedCustomer.claimNumber,
            "imei": this.selectedCustomer.imei
        };
        this.saveService.razorPayOrder(razorPayOrder)
            .subscribe(function (response) {
            _this.spinner.hide();
            if (response && response.success) {
                _this.doRazorPayPayment(response.result);
            }
            else if (response && response.error && response.error.message) {
                _this.errorMessage = response.error.message;
            }
            else {
                _this.errorMessage = "Something went wrong. Please try again later";
            }
        }, function (error) {
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.delay = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    CustomerComponent.prototype.verifySignature = function (checkoutResponse) {
        var _this = this;
        //this.errorMessage = "something went wrong";
        setTimeout(function () {
            _this.spinner.show();
        }, 100);
        this.saveService.razorPayVerifySignture(checkoutResponse)
            .subscribe(function (response) {
            _this.spinner.hide();
            if (!response || !response.success) {
                if (response.error && response.error.message) {
                    _this.errorMessage = response.error.message;
                }
                else {
                    _this.errorMessage = "Something went wrong. Please try again later";
                }
                _this.showPaymentFailedModal();
            }
            else {
                if (response.success) {
                    // remove razorpay div
                    var razorPayDiv = document.getElementsByClassName("razorpay-container");
                    razorPayDiv[0].remove();
                    _this.paymentStatus = "Credit";
                    _this.paymentId = checkoutResponse.razorpay_payment_id;
                    _this.orderId = checkoutResponse.razorpay_order_id;
                    //this.spinner.show();
                    //alert("Payment is successfull");
                    //this.showPaymentSuccessModal();
                    //$("#paymentsuccessmodal").modal('show');
                    //this.spinner.show();
                    _this.updateCustomerWithPayment();
                    //alert("Wait till payment is done");
                }
                else {
                    _this.showPaymentFailedModal();
                }
            }
        }, function (error) {
            _this.errorMessage = "Something went wrong. Please try again later";
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.triggerFalseClick = function () {
        var el = this.myDiv.nativeElement;
        el.click();
    };
    CustomerComponent.prototype.handler = function (response) {
        console.log("***************");
        console.log(response);
        this.spinner.show();
        this.verifySignature(response);
    };
    CustomerComponent.prototype.doRazorPayPayment = function (orderResponse) {
        var options = {
            "key": orderResponse.payment_api_key,
            "amount": orderResponse.amount,
            "name": "Amtrust",
            "order_id": orderResponse.id,
            "description": "Plan payment",
            "notes": {
                "claimNumber": this.selectedCustomer.claimNumber,
                "imei": this.selectedCustomer.imei
            },
            "handler": this.handler.bind(this),
            // "handler": (function (response) {
            //   console.log(response);
            //   this.spinner.show();
            //   // //alert(response.razorpay_payment_id);
            //   // this.showPaymentSuccessModal();
            //   //this.showModal = true;
            //   //this.spinner.hide();
            //   console.log(this.showModal);
            //   this.verifySignature(response);
            // }).bind(this),
            "prefill": {
                "name": this.selectedCustomer.customerFirstName,
                "email": this.selectedClaim.emailId,
                "contact": this.selectedCustomer.phoneNumber,
            },
            "theme": {
                "color": "red"
            }
        };
        var instance = new Razorpay(options);
        //instance.createPayment(options);
        // instance.on('payment.success', function(resp) {
        //   alert(resp.razorpay_payment_id),
        //   alert(resp.razorpay_order_id),
        //   alert(resp.razorpay_signature)});
        instance.open();
        // instance.on('payment.success', function(resp) {
        //   alert(resp.razorpay_payment_id),
        //   alert(resp.razorpay_order_id),
        //   alert(resp.razorpay_signature)}
        // );
    };
    // savepayment(){
    //   this.spinner.show();
    //   for(let pay of this.verifyOtp.customer.payments)
    //   {
    //     pay.paymentStatus=this.paymentStatus;
    //     pay.paymentRequestId=this.paymentRequestId;
    //     pay.paidAmount=this.verifyOtp.claim.applicableFee;
    //     pay.paymentId=this.payment;
    //   }
    //   this.saveService.saveDocument(this.verifyOtp.customer);
    //   setTimeout(() => {
    //     this.spinner.hide();
    //   }, 3000);
    //   console.log('Timed out..!!')
    // }
    // upload(uploadName) {
    //   this.spinner.show();
    //   console.log(this.verifyOtp.customer.claimNumber);
    //   const file = this.selectedFiles.item(0);
    //   console.log(file.name);
    //   var fileName=file.name;
    //   var allowedExtns=['jpg','jpeg','png','pdf'];
    //   var ext=fileName.substring(fileName.lastIndexOf('.')+1);
    //   console.log(ext)
    //   if (allowedExtns.indexOf(ext.toLocaleLowerCase()) == -1)
    //   {
    //     console.log('Extension Not Allowed');
    //     this.spinner.hide();
    //     alert('Only jpg/jpeg/png/pdf files are allowed.')
    //     return;
    //   }
    //   this.uploadService.uploadFile(file,this.verifyOtp.customer.claimNumber,uploadName);
    //   for(let doc of this.verifyOtp.customer.documents)
    //   {
    //     if (doc.documentName==uploadName){
    //       doc.downloadFileUrl=environment.AWS_FILE_LOCATION+this.verifyOtp.customer.claimNumber+'/'+uploadName+file.name;
    //       doc.status='Uploaded';
    //       //doc.agentStatus="PENDING";
    //       doc.fileName=file.name;
    //     }
    //   }
    //   this.saveService.saveDocument(this.verifyOtp.customer);
    //   sessionStorage.verifyOtp=JSON.stringify(this.verifyOtp); 
    //   setTimeout(() => {
    //     this.spinner.hide();
    //   }, 3000);
    //   console.log('Timed out..!!')
    //   }
    CustomerComponent.prototype.selectFile = function (event) {
        this.selectedFiles = event.target.files;
    };
    //select claim number and click on proceed
    CustomerComponent.prototype.submitClaimNumber = function () {
        //console.log("selected claim number");
        //console.log(this.selectedClaimNumber);
        if (this.customers && this.selectedClaimNumber) {
            for (var _i = 0, _a = this.customers; _i < _a.length; _i++) {
                var cust = _a[_i];
                if (cust.claimNumber && cust.claimNumber == this.selectedClaimNumber) {
                    this.selectedCustomer = cust;
                    this.getClaimsByImei();
                    break;
                }
            }
        }
    };
    CustomerComponent.prototype.isAddressProvided = function () {
        return this.addressSubmitted && this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
            && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
            && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
    };
    CustomerComponent.prototype.isClaimAddressPresent = function () {
        return this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
            && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
            && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
    };
    CustomerComponent.prototype.getClaimsByImei = function (updatePayment) {
        var _this = this;
        this.spinner.show();
        if (this.selectedCustomer != null && this.selectedCustomer.imei != null) {
            this.customerService.getClaimsByImei(this.selectedCustomer.imei, this.selectedCustomer.policyId, true)
                .subscribe(function (response) {
                //console.log("getclaimsbyimei");
                //console.log(response);
                var claims = response;
                //console.log( this.selectedCustomer);
                var indexc = _this.selectedCustomer.claimNumber.indexOf("t");
                if (indexc != -1) {
                    _this.selectedCustomer.claimNumber = _this.selectedCustomer.claimNumber.substring(0, indexc);
                }
                for (var _i = 0, claims_1 = claims; _i < claims_1.length; _i++) {
                    var claim = claims_1[_i];
                    if (claim.arcContract) {
                        var index = claim.arcContract.indexOf("t");
                        if (index != -1) {
                            claim.arcContract = claim.arcContract.substring(0, index);
                        }
                    }
                    //console.log(claim.lockCodeDeacription);
                    if (claim.arcContract == _this.selectedCustomer.claimNumber) {
                        //console.log("lockcodedec before :: " + claim.lockCodeDeacription);
                        if (claim.lockCodeDeacription.includes("-")) {
                            var passwordArr = claim.lockCodeDeacription.split("-");
                            claim.lockCodeDeacription = passwordArr[1];
                            //console.log("lockcodedec after :: " + claim.lockCodeDeacription);
                        }
                        if (claim.country == '' || claim.country == null) {
                            claim.country = 'India';
                        }
                        if (claim.dispatchCountry == '' || claim.dispatchCountry == null) {
                            claim.dispatchCountry = 'India';
                        }
                        if (claim.isPhoneLocked == '' || claim.isPhoneLocked == null) {
                            claim.isPhoneLocked = 'No';
                        }
                        _this.selectedClaim = claim;
                        // switch tabs if data available
                        if (_this.selectedClaim.pickupUnit) {
                            _this.wizardselectedCnt = 2;
                        }
                        if (_this.selectedClaim.dispatchUnit) {
                            _this.wizardselectedCnt = 3;
                        }
                        if (_this.selectedClaim.colorOfDevice) {
                            _this.wizardselectedCnt = 4;
                            _this.isNextBtnEnabled = false;
                        }
                        //
                        if (updatePayment && _this.getPaymentAmount() != null) {
                            _this.updateClaimWithPayment();
                        }
                        if (_this.isClaimAddressPresent()) {
                            _this.addressSubmitted = true;
                        }
                    }
                }
                if (_this.selectedClaim == null) {
                    _this.errorMessage = "Claim not found";
                }
                //list document to be process
                _this.setDocumentsToBeProcess();
                _this.spinner.show();
                //check if payment enabled or not
                //alert(this.isPaymentAllowed() +"##"+ !this.isPaymentPaid());
                //alert((this.isPaymentAllowed() && !this.isPaymentPaid()));
                if (_this.isPaymentAllowed() && !_this.isPaymentPaid()) {
                    _this.showPayment = true;
                }
                _this.spinner.hide();
            }, function (error) {
                _this.spinner.hide();
                _this.errorMessage = "Something went wrong please try again later.";
            });
        }
    };
    CustomerComponent.prototype.updateClaimWithPayment = function () {
        //console.log('updateClaimWithPayment');
        this.selectedClaim.serviceTransactionRef = this.paymentId;
        this.selectedClaim.feePaidByCustomer = this.getPaymentAmount().toString();
        var currentDate = new Date();
        this.selectedClaim.paymentDate = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear();
        this.updateClaim(true);
    };
    CustomerComponent.prototype.getPaymentAmount = function () {
        if (this.selectedCustomer && this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0) {
            for (var _i = 0, _a = this.selectedCustomer.payments; _i < _a.length; _i++) {
                var payment = _a[_i];
                if (payment.paymentAmount) {
                    return payment.paymentAmount;
                }
            }
        }
    };
    CustomerComponent.prototype.setDocumentsToBeProcess = function () {
        if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
            this.nextDocumnets = this.getDocumentsTobUpload();
            this.claimDocument = this.getDocumentsToUpload();
            //console.log("#document list#");
            //console.log(this.claimDocument);
        }
        /*if (this.nextDocumnets && this.nextDocumnets.length > 0) {
          this.tempDocument = this.nextDocumnets[0];
          this.lastDocument = this.nextDocumnets[this.nextDocumnets.length - 1];
        }*/
    };
    CustomerComponent.prototype.showPaymentOption = function () {
        //alert(!this.tempDocument+"::"+this.isPaymentPaid());
        return !this.tempDocument && !this.isPaymentPaid();
    };
    CustomerComponent.prototype.getDocumentsToUpload = function () {
        var documents = [];
        if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
            //console.log("#listFromApi#");
            //console.log(this.selectedCustomer.documents);
            for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                var doc = _a[_i];
                //if ((!doc.url || doc.status == "REJECTED") && doc.documentType != 'HDFC Payment Slip') {
                if (doc.documentType != 'HDFC Payment Slip') {
                    documents.push(doc);
                }
            }
        }
        return documents;
    };
    CustomerComponent.prototype.getDocumentsTobUpload = function () {
        var documnets = [];
        if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
            for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                var doc = _a[_i];
                if (!doc.url || doc.status == "REJECTED") {
                    documnets.push(doc);
                }
            }
        }
        return documnets;
    };
    /*onFileChangeUpload(event) {
      console.log("onFileChangeUpload");
      this.errorMessage = null;
      if (event.target.files && event.target.files.length > 0) {
        for (const file of event.target.files) {
          if (this.tempDocument.allowedFileTypes && this.tempDocument.allowedFileTypes.length > 0) {
            var ext=file.name.substring(file.name.lastIndexOf('.')+1);
            console.log(this.tempDocument.allowedFileTypes);
            console.log("ext :: " + ext);
            if (this.tempDocument.allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
              this.errorMessage = "Allowed file types are " + this.tempDocument.allowedFileTypes.join(", ");
              return;
            }
          }
          
          this.fileUploadData = new FileUploadData();
          const input = new FormData();
          input.append('file', file, file.name);
          input.append("fileName", uuid());
          input.append("functionInstanceName", 'FLOW');
          input.append("entityType", "ClaimCustomer");
          input.append("entityRef", this.selectedCustomer._id);
          this.fileUploadData.formData = input
          
          this.fileUploadData.userFileName = file.name;
          this.fileUploadData.documentName = this.tempDocument.documentName;
          this.tempDocument.userFileName = file.name;
          
        }
      }
      //this.fileInput.nativeElement.value = '';
    }*/
    CustomerComponent.prototype.onFileChangeUpload = function (event, id) {
        this.tempDocument = this.selectedCustomer.documents[id];
        this.tempDocument.allowedFileTypes = ["jpg", "jpeg", "png", "pdf"];
        //allowed file-size
        //console.log("onFileChangeUpload");
        this.prevErrorMsg = this.errorMessage;
        this.errorMessage = null;
        // this.tempDocument=
        if (event.target.files && event.target.files.length > 0) {
            for (var _i = 0, _a = event.target.files; _i < _a.length; _i++) {
                var file = _a[_i];
                if (this.tempDocument.allowedFileTypes && this.tempDocument.allowedFileTypes.length > 0) {
                    var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
                    //console.log(this.tempDocument.allowedFileTypes);
                    //console.log("ext :: " + ext);  
                    if (this.tempDocument.allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
                        this.errorMessage = "Allowed file types are " + this.tempDocument.allowedFileTypes.join(", ");
                        this.tempDocument = null;
                        this.nextDisable = true;
                        return;
                    }
                    else {
                        this.nextDisable = false;
                    }
                }
                if ((file.size / 1024) >= 1000) {
                    this.errorMessage = "Allowed file size is limited to max 500 KB ";
                    console.log("file-size-error :: ");
                    event.target.value = "";
                    return;
                }
                //console.log("file-size-error :: ");  
                this.fileUploadData = new FileUploadData();
                var input = new FormData();
                input.append('file', file, file.name);
                input.append("fileName", uuid());
                input.append("functionInstanceName", 'FLOW');
                input.append("entityType", "ClaimCustomer");
                input.append("entityRef", this.selectedCustomer._id);
                this.fileUploadData.formData = input;
                //console.log("checkTempDocument1");
                //console.log(this.tempDocument);
                this.fileUploadData.userFileName = file.name;
                this.fileUploadData.documentName = this.tempDocument.documentName;
                this.fileUploadData.documentType = this.tempDocument.documentType;
                this.tempDocument.userFileName = file.name;
                this.uploadFileDocument.push(this.fileUploadData);
                //this.uploadFileDocument[this.tempDocument.documentName]=this.fileUploadData;
                this.uploadFileTempDocument[this.tempDocument.documentName] = this.tempDocument;
                //console.log("checkTempDocumentR");
                //console.log(this.tempDocument);
                console.log(this.uploadFileTempDocument);
            }
        }
        //this.fileInput.nativeElement.value = '';
    };
    CustomerComponent.prototype.onPaymentSlipFileChange = function (event) {
        console.log("onPaymentSlipFileChange");
        this.errorMessage = null;
        var allowedFileTypes = ["jpg", "jpeg", "png", "pdf"];
        //allowed file-size
        if (event.target.files && event.target.files.length > 0) {
            for (var _i = 0, _a = event.target.files; _i < _a.length; _i++) {
                var file = _a[_i];
                if (allowedFileTypes && allowedFileTypes.length > 0) {
                    var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
                    console.log(allowedFileTypes);
                    console.log("ext :: " + ext);
                    if (allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
                        this.errorMessage = "Allowed file types are " + allowedFileTypes.join(", ");
                        return;
                    }
                }
                if ((file.size / 1024) > 500) {
                    this.errorMessage = "Allowed file size is limited to max 500 KB ";
                    event.target.value = "";
                    return;
                }
                this.fileUploadData = new FileUploadData();
                var input = new FormData();
                input.append('file', file, file.name);
                input.append("fileName", uuid());
                input.append("functionInstanceName", 'FLOW');
                input.append("entityType", "ClaimCustomer");
                input.append("entityRef", this.selectedCustomer._id);
                this.fileUploadData.formData = input;
                this.fileUploadData.userFileName = file.name;
                this.fileUploadData.documentName = this.paymentDocument.documentName;
                this.paymentDocument.userFileName = file.name;
            }
        }
    };
    CustomerComponent.prototype.proccessPreviousDocumnet = function () {
        this.errorMessage = null;
        if (this.previousDocuments && this.previousDocuments.length > 0) {
            this.nextDocumnets.push(JSON.parse(JSON.stringify(this.tempDocument)));
            this.tempDocument = this.previousDocuments[this.previousDocuments.length - 1];
            //this.addDocumnetToSubmitDocumnets(this.tempDocument);
            var index = this.previousDocuments.indexOf(this.tempDocument);
            if (index != -1) {
                this.previousDocuments.splice(index, 1);
            }
        }
    };
    CustomerComponent.prototype.processNextdocument = function () {
        this.errorMessage = null;
        if (this.tempDocument && this.nextDocumnets.length > 0) {
            this.replaceCustomerDocs(this.tempDocument);
            this.uploadDocument(1);
        }
    };
    CustomerComponent.prototype.replaceCustomerDocs = function (processDoc) {
        if (processDoc && this.selectedCustomer && this.selectedCustomer.documents) {
            var docTobeRemove = null;
            for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                var doc = _a[_i];
                if (doc.documentName && processDoc.documentName && doc.documentName == processDoc.documentName) {
                    docTobeRemove = doc;
                }
            }
            if (docTobeRemove) {
                var index = this.selectedCustomer.documents.indexOf(docTobeRemove);
                if (index != -1) {
                    this.selectedCustomer.documents.splice(index, 1);
                    this.selectedCustomer.documents.push(processDoc);
                }
            }
            console.log("replaceCustomerDocs");
            console.log(this.selectedCustomer.documents);
        }
    };
    CustomerComponent.prototype.displayNext = function () {
        this.previousDocuments.push(JSON.parse(JSON.stringify(this.tempDocument)));
        this.tempDocument = this.nextDocumnets[this.nextDocumnets.length - 1];
        var index = this.nextDocumnets.indexOf(this.tempDocument);
        if (index != -1) {
            this.nextDocumnets.splice(index, 1);
        }
        if (this.nextDocumnets.length == 0) {
            this.tempDocument = null;
            if (this.isPaymnetAllow() && !this.isPaymentPaid()) {
                this.showPayment = true;
            }
            else {
                if (!this.isFlowNeedToBeUpdate()) {
                    this.updateCustomer();
                }
                //alert("success");
            }
        }
    };
    CustomerComponent.prototype.isLastDocument = function () {
        if (this.nextDocumnets && this.nextDocumnets.length > 0 && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0 && this.lastDocument) {
            //console.log(this.lastDocument.documentName);
            //console.log(this.tempDocument.documentName);
            //console.log(this.lastDocument.documentName == this.tempDocument.documentName);
            return this.lastDocument.documentName == this.tempDocument.documentName;
        }
        return false;
    };
    CustomerComponent.prototype.isPaymentPaid = function () {
        if (!this.selectedCustomer.payments || this.selectedCustomer.payments.length == 0) {
            return true;
        }
        return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentStatus.toUpperCase() == "PAID";
    };
    CustomerComponent.prototype.isPaymnetAllow = function () {
        return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentAmount;
    };
    CustomerComponent.prototype.isPaymentAllowed = function () {
        return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentAmount;
    };
    CustomerComponent.prototype.uploadPaymentSlip = function () {
        var _this = this;
        if (this.fileUploadData) {
            this.spinner.show();
            this.customerService.uploadDocument(this.fileUploadData.formData)
                .subscribe(function (response) {
                //console.log("upload payment slip");
                //console.log(response);
                _this.paymentDocument.url = response.url;
                _this.paymentDocument.downloadFileUrl = response.downloadFileUrl;
                _this.paymentDocument.fileName = response.fileName;
                _this.paymentDocument.fullDataUrl = response.fullDataUrl;
                _this.replaceDocumentByDocumentType(_this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP);
                for (var _i = 0, _a = _this.selectedCustomer.payments; _i < _a.length; _i++) {
                    var payment = _a[_i];
                    if (payment) {
                        payment.paymentChannel = _this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP;
                        payment.paymentStatus = "PAID";
                    }
                }
                _this.updateCustomer();
                //show payment success modal
                _this.showPaymentSuccessModal();
            }, function (error) {
                _this.errorMessage = "Something went wrong. Please try again";
                _this.fileUploadData = null;
                _this.spinner.hide();
            });
        }
    };
    CustomerComponent.prototype.replaceDocumentByDocumentType = function (documentType) {
        if (documentType && this.selectedCustomer) {
            if (!this.selectedCustomer.documents) {
                this.selectedCustomer.documents = [];
            }
            var existingDoc = null;
            for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                var doc = _a[_i];
                if (doc && doc.documentType == this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP) {
                    existingDoc = doc;
                }
            }
            if (existingDoc != null) {
                var index = this.selectedCustomer.documents.indexOf(existingDoc);
                if (index != -1) {
                    this.selectedCustomer.documents.splice(index, 1);
                    this.selectedCustomer.documents.splice(index, 0, this.paymentDocument);
                }
            }
            else {
                this.selectedCustomer.documents.push(this.paymentDocument);
            }
        }
    };
    CustomerComponent.prototype.uploadDocument2 = function (getType) {
        var _this = this;
        console.log("fileUploadData");
        console.log(this.fileUploadData);
        if (this.fileUploadData) {
            this.customerService.uploadDocument(this.fileUploadData.formData)
                .subscribe(function (response) {
                console.log("file uploaded successfully");
                console.log(response);
                _this.tempDocument.url = response.url;
                _this.tempDocument.downloadFileUrl = response.downloadFileUrl;
                _this.tempDocument.fileName = response.fileName;
                _this.tempDocument.fullDataUrl = response.fullDataUrl;
                _this.tempDocument.fullFileUrl = response.fullFileUrl;
                _this.updateCustomer();
                _this.fileUploadData = null;
                if (getType == 2) {
                    _this.showDocumentSuccessModal();
                }
            }, function (error) {
                console.log('error in file upload');
                console.log(error);
                _this.errorMessage = "Something went wrong. Please try again";
                _this.fileUploadData = null;
                _this.spinner.hide();
            });
        }
        else {
            if (getType == 2) {
                this.showDocumentSuccessModal();
            }
        }
        /* else if (this.tempDocument.mandatory && !this.tempDocument.url) {
           this.errorMessage = this.tempDocument.documentType + " is mandatory.";
           this.spinner.hide();
         }*/
        /*else {
          this.displayNext();
        }*/
    };
    CustomerComponent.prototype.uploadDocument = function (getType) {
        var _this = this;
        if (this.uploadFileDocument.length > 0) {
            for (var _i = 0, _a = this.uploadFileDocument; _i < _a.length; _i++) {
                var doc = _a[_i];
                if (doc.userFileName == null) {
                    this.errorMessage = "Upload " + doc.documentType;
                    return;
                }
            }
            this.spinner.show();
            var numberOfdocs = this.uploadFileDocument.length;
            this.uploadFileDocument.forEach(function (subDoc) {
                //console.log(subDoc);
                _this.customerService.uploadDocument(subDoc.formData)
                    .subscribe(function (response) {
                    //console.log("file uploaded successfully");
                    //console.log(response);
                    //console.log("file uploaded successfully tempDoc");
                    //console.log(this.tempDocument);
                    _this.tempDocument = _this.uploadFileTempDocument[subDoc.documentName];
                    //console.log("file uploaded successfully tempDocUpdated");
                    //console.log(this.tempDocument);
                    _this.tempDocument.url = response.url;
                    _this.tempDocument.downloadFileUrl = response.downloadFileUrl;
                    _this.tempDocument.fileName = response.fileName;
                    _this.tempDocument.fullDataUrl = response.fullDataUrl;
                    _this.tempDocument.fullFileUrl = response.fullFileUrl;
                    _this.fileUploadData = null;
                    //update customer 
                    numberOfdocs--;
                    if (numberOfdocs == 0) {
                        _this.spinner.hide();
                        _this.updateCustomer();
                    }
                }, function (error) {
                    console.log('error in file upload');
                    console.log(error);
                    _this.errorMessage = "Something went wrong. Please try again";
                    _this.fileUploadData = null;
                    _this.nextDisable = true;
                    _this.showPayment = false;
                    _this.spinner.hide();
                });
            });
            if (getType == 2) {
                this.showDocumentSuccessModal();
            }
            //this.spinner.hide();
            //update customer  - all at time
            //	this.updateCustomer();
            this.uploadFileDocument = [];
        }
        else {
            if (getType == 2) {
                this.showDocumentSuccessModal();
            }
        }
    };
    CustomerComponent.prototype.updateNextAndPreviousDocs = function () {
        if (this.previousDocuments && this.previousDocuments.length > 0) {
            for (var index = 0; index < this.previousDocuments.length; index++) {
                for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                    var doc = _a[_i];
                    if (doc.documentName && this.previousDocuments[index].documentName && doc.documentName && this.previousDocuments[index].documentName) {
                        this.previousDocuments.splice(index, 0, doc);
                    }
                }
            }
        }
        if (this.nextDocumnets && this.nextDocumnets.length > 0) {
            for (var index = 0; index < this.nextDocumnets.length; index++) {
                for (var _b = 0, _c = this.selectedCustomer.documents; _b < _c.length; _b++) {
                    var doc = _c[_b];
                    if (doc.documentName && this.nextDocumnets[index].documentName && doc.documentName && this.nextDocumnets[index].documentName) {
                        this.nextDocumnets.splice(index, 0, doc);
                    }
                }
            }
        }
    };
    CustomerComponent.prototype.isFlowNeedToBeUpdate = function () {
        // && this.selectedCustomer.documents
        console.log('isFlowNeedToBeUpdate', this.selectedCustomer);
        if (this.selectedCustomer) {
            // for (let doc of this.selectedCustomer.documents) {
            //   if (doc.mandatory && !doc.url) {
            //     return false;
            //   }
            // }
            if (this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0) {
                for (var _i = 0, _a = this.selectedCustomer.payments; _i < _a.length; _i++) {
                    var paymnet = _a[_i];
                    if (!paymnet.paymentAmount) {
                        this.selectedCustomer.statusCd = "IDLE";
                        return true;
                    }
                    if (paymnet.paymentStatus && paymnet.paymentStatus.toUpperCase() == "PAID") {
                        this.selectedCustomer.statusCd = "IDLE";
                        return true;
                    }
                }
            }
            else {
                this.selectedCustomer.statusCd = "IDLE";
                return true;
            }
        }
        return false;
    };
    CustomerComponent.prototype.updateCustomer = function (paymentSuccess) {
        var _this = this;
        this.spinner.show();
        this.customerService.updateClaimCustomer(this.selectedCustomer, this.isFlowNeedToBeUpdate())
            .subscribe(function (response) {
            //console.log("customerupdated");
            //console.log(response);
            _this.spinner.hide();
            if (response && response.success && response.result) {
                _this.selectedCustomer = response.result;
                //this.updateNextAndPreviousDocs();
                //console.log(this.tempDocument);
                if (_this.tempDocument != null) {
                    _this.displayNext();
                }
                if (paymentSuccess) {
                    _this.getClaimsByImei(true);
                    //this.showPaymentSuccessModal();
                }
            }
            else if (response && !response.success) {
                if (response.error) {
                    if (typeof response.error == "string") {
                        _this.errorMessage = response.error;
                    }
                    if (response.error && response.error.message) {
                        _this.errorMessage = response.error.message;
                    }
                }
                else {
                    _this.errorMessage = "unable to update customer";
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    CustomerComponent.prototype.addDocumnetToSubmitDocumnets = function (doc) {
        if (doc) {
            for (var _i = 0, _a = this.selectedCustomer.documents; _i < _a.length; _i++) {
                var subDoc = _a[_i];
                if (subDoc.documentName == doc.documentName) {
                    return;
                }
            }
            this.submitDocuments.push(doc);
        }
    };
    CustomerComponent.prototype.replaceDocument = function () {
        if (this.tempDocument && this.submitDocuments) {
            var index = this.submitDocuments.indexOf(this.tempDocument);
            if (index != -1) {
                this.previousDocuments.push(this.tempDocument);
                this.submitDocuments.splice(index, 1);
            }
        }
    };
    CustomerComponent.prototype.onSelectPickupSameAsDispatch = function (value) {
        console.log("onSelectPickupSameAsDispatch");
        if (this.isPickupAddressSameAsDispatchAddress) {
            this.selectedClaim.dispatchArea = this.selectedClaim.pickupArea;
            this.selectedClaim.dispatchUnit = this.selectedClaim.pickupUnit;
            this.selectedClaim.dispatchCity = this.selectedClaim.pickupCity;
            this.selectedClaim.dispatchCountry = this.selectedClaim.country;
            this.selectedClaim.dispatchState = this.selectedClaim.pickupState;
            this.selectedClaim.dispatchPinCode = this.selectedClaim.pinCode;
        }
    };
    CustomerComponent.prototype.onClaimAddressSubmit = function () {
        this.spinner.show();
        this.updateClaim();
    };
    CustomerComponent.prototype.updateClaim = function (paymentDone) {
        var _this = this;
        this.customerService.updateClaim(this.selectedCustomer.imei, this.selectedClaim)
            .subscribe(function (response) {
            //console.log("updateclaim");
            //console.log(response);
            _this.selectedCustomer.pickupAddress = _this.selectedClaim.pickupArea;
            _this.selectedCustomer.pickupUnit = _this.selectedClaim.pickupUnit;
            _this.selectedCustomer.pickupCity = _this.selectedClaim.pickupCity;
            _this.selectedCustomer.pickupState = _this.selectedClaim.pickupState;
            _this.selectedCustomer.pickupPincode = _this.selectedClaim.pickupPinCode;
            _this.selectedCustomer.dispatchAddress = _this.selectedClaim.dispatchArea;
            _this.selectedCustomer.dispatchUnit = _this.selectedClaim.dispatchUnit;
            _this.selectedCustomer.dispatchCity = _this.selectedClaim.dispatchCity;
            _this.selectedCustomer.dispatchState = _this.selectedClaim.dispatchState;
            _this.selectedCustomer.dispatchPincode = _this.selectedClaim.dispatchPinCode;
            _this.selectedCustomer.deviceColor = _this.selectedClaim.colorOfDevice;
            _this.selectedCustomer.deviceMemory = _this.selectedClaim.memorycapacity;
            _this.selectedCustomer.deviceLock = _this.selectedClaim.lockCodeDeacription;
            _this.selectedCustomer.deviceLockCode = _this.selectedClaim.lockCode;
            console.log("payment done", paymentDone);
            if (paymentDone) {
                setTimeout(function () {
                    _this.spinner.hide();
                    _this.showPaymentSuccessModal();
                }, 500);
            }
            else {
                _this.updateCustomer();
            }
            if (response.success) {
                _this.addressSubmitted = true;
            }
            if (!response.success && response.error && response.error.message) {
                _this.errorMessage = response.error.message;
            }
            _this.spinner.hide();
        }, function (error) {
            console.log("update claim error");
            console.log(error);
            _this.spinner.hide();
        });
    };
    CustomerComponent.prototype.onPaymentMethodSelection = function (paymentMethod) {
        if (paymentMethod && paymentMethod == "PAY_AT_HDFC") {
            this.paymentDocument = new Documents();
            this.paymentDocument.documentName = uuid();
            this.paymentDocument.documentType = this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP;
            this.paymentDocument.description = "HDFC payment slip";
            this.paymentDocument.mandatory = true;
        }
        else {
            this.paymentDocument = null;
        }
    };
    CustomerComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-lg modal-dialog-centered'
        });
    };
    CustomerComponent.prototype.validateForm = function () {
        var formTab, formTabInput, i, valid = true;
        this.errorMessage = '';
        formTab = $('.tab-pane');
        {
            formTabInput = formTab[0].getElementsByClassName("required-input");
            for (i = 0; i < formTabInput.length; i++) {
                if (formTabInput[i].value == "") {
                    if (formTabInput[i].getAttribute('dataType') == 'File') {
                        this.errorMessage = formTabInput[i].name + " Input is Mandatory.";
                    }
                    else {
                        this.errorMessage = formTabInput[i].getAttribute('data_field') + " Input is Mandatory.";
                        formTabInput[i].focus();
                    }
                    valid = false;
                    return valid;
                }
                else {
                    if (formTabInput[i].getAttribute('dataType') == 'Pincode') {
                        var regex = /^[1-9][0-9]{5}$/;
                        if (!regex.test(formTabInput[i].value)) {
                            this.errorMessage = formTabInput[i].name + " Input is Invalid.";
                            formTabInput[i].focus();
                            valid = false;
                            return valid;
                        }
                    }
                }
            }
        }
        return valid; // return the valid status
    };
    CustomerComponent.prototype.nextPrev = function (getCount) {
        if (getCount == 1 && !this.validateForm())
            return false;
        if (getCount == 1) // call the api only on next btn
         {
            if (this.wizardselectedCnt > 0 && this.wizardselectedCnt < 4) {
                this.onClaimAddressSubmit();
            }
            /* document verification commented
          else if (this.wizardselectedCnt == 4) {
    
            if (this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
              for (let doc of this.selectedCustomer.documents) {
                if (doc.url == null && (!doc.status || doc.status == "PENDING" || doc.status == "REJECTED")) {
                  if (this.uploadFileDocument.length == 0) {
                    this.errorMessage = "Document " + doc.documentType + " is mandatory";
                    return;
                  }
                  else {
                    var docPresent = false;
                    for (let uploadDoc of this.uploadFileDocument) {
                      if (uploadDoc && uploadDoc.documentName == doc.documentName) {
                        docPresent = true;
                      }
                    }
                    if (!docPresent) {
                      this.errorMessage = "Document " + doc.documentType + " is mandatory";
                      return;
                    }
                  }
    
                }
              }
            }
    
            this.uploadDocument(1);
          }
          */
        }
        this.wizardselectedCnt = this.wizardselectedCnt + getCount;
        this.isSubmitBtnEnabled = false;
        this.isDocumentSubmitBtnEnabled = false;
        this.isPreviousBtnEnabled = true;
        this.isNextBtnEnabled = true;
        if (this.wizardselectedCnt < 1) {
            this.isPreviousBtnEnabled = false;
        }
        else if (this.wizardselectedCnt == 1) {
            this.isPreviousBtnEnabled = false;
        }
        else if (this.wizardselectedCnt == 4) {
            if (!this.showPayment) {
                this.isDocumentSubmitBtnEnabled = true;
            }
            this.isNextBtnEnabled = false;
        }
        else if (this.wizardselectedCnt == 5) {
            this.isNextBtnEnabled = false;
            this.isSubmitBtnEnabled = true;
        }
    };
    CustomerComponent.prototype.nextLast = function (getType) {
        if (!this.validateForm())
            return false;
        if (getType == 1) {
            this.uploadDocument(2);
        }
        else if (getType == 2) {
            this.uploadPaymentSlip();
        }
    };
    CustomerComponent.prototype.onSelectPin = function (getType) {
        // alert('ty'+getType)
    };
    CustomerComponent.prototype.resetModal = function () {
        this.wizardselectedCnt = 1;
    };
    //show payment success modal
    CustomerComponent.prototype.showPaymentSuccessModal = function () {
        var _this = this;
        this.showModal = true;
        setTimeout(function () {
            _this.changeDetectorRef.detectChanges();
        }, 500);
    };
    //show payment failed modal
    CustomerComponent.prototype.showPaymentFailedModal = function () {
        this.showFailedModal = true;
    };
    //show document success modal
    CustomerComponent.prototype.showDocumentSuccessModal = function () {
        this.showDocumentModal = true;
    };
    CustomerComponent.prototype.logoutCustomer = function () {
        this.universalUser.removeUser();
    };
    CustomerComponent.prototype.homeCustomer = function () {
        this.router.navigate(['/#/pg/customer']);
    };
    return CustomerComponent;
}());
export { CustomerComponent };
