import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
var DataSharingService = /** @class */ (function () {
    function DataSharingService() {
    }
    DataSharingService.prototype.setSharedObject = function (sharedObject) {
        this.sharedObject = sharedObject;
    };
    DataSharingService.prototype.getSharedObject = function () {
        var tempObject = null;
        if (this.sharedObject) {
            tempObject = JSON.parse(JSON.stringify(this.sharedObject));
            this.sharedObject = null;
        }
        return tempObject;
    };
    return DataSharingService;
}());
export { DataSharingService };
var UniversalUser = /** @class */ (function () {
    function UniversalUser() {
    }
    UniversalUser.prototype.setUser = function (user, shouldRedirect) {
        window.sessionStorage.setItem(UniversalUser.UNIVERSAL_USER, JSON.stringify(user));
        this.user = user;
        if (shouldRedirect) {
            window.localStorage.setItem(UniversalUser.SESSION_AVAILABLE, new Date().getTime().toString());
        }
    };
    UniversalUser.prototype.getUser = function () {
        if (!this.user) {
            this.user = JSON.parse(window.sessionStorage.getItem(UniversalUser.UNIVERSAL_USER));
        }
        return this.user;
    };
    UniversalUser.prototype.removeUser = function () {
        sessionStorage.removeItem(UniversalUser.UNIVERSAL_USER);
    };
    UniversalUser.UNIVERSAL_USER = "UNIVERSAL_USER";
    UniversalUser.SESSION_AVAILABLE = "SESSION_AVAILABLE";
    return UniversalUser;
}());
export { UniversalUser };
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, universalUser) {
        this.router = router;
        this.universalUser = universalUser;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        console.log("**** can activate *****");
        if (this.universalUser.getUser()) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/pg/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
var AntiAuthGuard = /** @class */ (function () {
    function AntiAuthGuard(router, universalUser) {
        this.router = router;
        this.universalUser = universalUser;
    }
    AntiAuthGuard.prototype.canActivate = function (route, state) {
        if (this.universalUser.getUser()) {
            // logged in so return false and navigate to home page
            this.router.navigate(['/']);
            return false;
        }
        // not logged in so redirect to login page
        return true;
    };
    return AntiAuthGuard;
}());
export { AntiAuthGuard };
var AlertService = /** @class */ (function () {
    function AlertService(router) {
        var _this = this;
        this.router = router;
        this.subject = new Subject();
        this.keepAfterNavigationChange = false;
        // clear alert message on route change
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (_this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    _this.keepAfterNavigationChange = false;
                }
                else {
                    // clear alert
                    _this.subject.next();
                }
            }
        });
    }
    AlertService.prototype.success = function (message, keepAfterNavigationChange, timeout) {
        var _this = this;
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        if (timeout === void 0) { timeout = 5000; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        if (timeout && timeout > 0) {
            setTimeout(function () {
                // clear alert after timeout
                _this.subject.next();
            }, timeout);
        }
    };
    AlertService.prototype.error = function (message, keepAfterNavigationChange, timeout) {
        var _this = this;
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        if (timeout && timeout > 0) {
            setTimeout(function () {
                // clear alert after timeout
                _this.subject.next();
            }, timeout);
        }
    };
    AlertService.prototype.getMessage = function () {
        return this.subject.asObservable();
    };
    return AlertService;
}());
export { AlertService };
