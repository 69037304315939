<!--div *ngIf="verifyOtp.customer!=null"-->

<div class="login-wrapper " *ngIf="customers != null && customers.length == 1 &&  customers[0].statusCd != 'ACTIVE'">
    <div class="bg-pic">
        <img src="assets/img/bg_login.png" alt="" class="lazy">
    </div>
    <div class="login-container bg-white">
        <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src="assets/img/logo.png" alt="logo" class="logoImage">
            <p class="keepWord" *ngIf="this.customersFiltered == null || this.customersFiltered.length == 0">Your claims are under processed</p>
            <div class="col-sm-12 no-padding clearfix">
                <p i18n="@@copyright" class="keepWord">Copyright © 2020 | AmTrust Mobile Solutions India Pvt. Ltd.</p>
            </div>
        </div>
    </div>
</div>

<div class="login-wrapper " *ngIf="customers && customers.length > 1 && selectedCustomer == null && verifyOtp.customer!=null">
    <div class="bg-pic">
        <img src="assets/img/bg_login.png" alt="" class="lazy">
    </div>
    <div class="login-container bg-white">
        <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-20 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src="assets/img/logo.png" alt="logo" class="logoImage">
            <p i18n="@@under-process" class="keepWord" *ngIf="this.customersFiltered == null || this.customersFiltered.length == 0">Your claims are under processed</p>
            <h3 i18n="@@select-claim" *ngIf="this.customersFiltered != null && this.customersFiltered.length > 1">Select Repair Request</h3>
            <p i18n="@@multi-claim" class="keepWord" *ngIf="this.customersFiltered.length>1">There are multiple repair requests against the provided phone number</p>
            <form id="form-login" class="p-t-b-15" role="form" action="">
                <div *ngIf="this.customersFiltered != null && this.customersFiltered.length > 1" class="section_height">
                    <div class="row paymentWaysBlock" *ngFor="let cust of customers">
                        <div class="col-xs-12" *ngIf="cust.statusCd=='ACTIVE'">
                            <label class="radioContainer regular-radio">
					<input type="radio" [(ngModel)]="selectedClaimNumber" name="selectedClaimNumber"
					[value]="cust.claimNumber" />
					<i></i>
					<h4>{{ cust.make }} - {{ cust.model }} - {{ cust.imei }}</h4>
				</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.customersFiltered != null && this.customersFiltered.length > 1" class="m-login__form-action p-t-35">
                    <button i18n="@@proceed" (click)="submitClaimNumber()" id="m_login_signin_submit" class="btn btn-primary btn-cons m-t-10">Proceed</button>
                </div>
                
            </form>
            <div class="col-sm-12 no-padding clearfix">
                <p i18n="@@copyright" class="keepWord">Copyright © 2020 | AmTrust Mobile Solutions India Pvt. Ltd.</p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedClaimNumber != null && selectedCustomer != null && selectedClaim != null && verifyOtp.customer!=null">
    <!--div-->
    <nav class="page-sidebar" data-pages="sidebar">
        <div class="sidebar-header">
            <img src="assets/img/logo.png" alt="logo" class="brand" width="78" height="22">
            <div class="sidebar-header-controls">
                <button type="button" class="btn btn-xs sidebar-slide-toggle btn-link m-l-20 hidden-md-down" data-pages-toggle="#appMenu"><i class="fa fa-angle-down fs-16"></i></button>
                <button type="button" class="btn btn-link hidden-md-down" data-toggle-pin="sidebar"><i class="fa fs-12"></i></button>
            </div>
        </div>
        <div class="sidebar-menu">
            <ul class="menu-items">
                <li class="m-t-30 ">
                    <span class="bg-success icon-thumbnail"><a href="/#/pg/customer"><img src="assets/img/DashboardIcon.png" class="sidebarIcon"/></a></span>
                    <a i18n="@@dashboard" href="/#/pg/customer" class="detailed active"><span class="title">Dashboard</span></a>
                </li>
                <li class="">
                    <span class="icon-thumbnail"><a href="/#/pg/customer"><img src="assets/img/MyclaimsIcon.png" class="sidebarIcon"/></a></span>
                    <!--a href="/#/pg/customer"><span class="title">My Claims</span> </a-->
                    <a i18n="@@my-claims" href (click)="homeCustomer()"><span class="title">My Repair Request</span> </a>
                </li>
                <li class="">
                    <span class="icon-thumbnail"><a href (click)="logoutCustomer()"><img src="assets/img/LogoutIcon.png" class="sidebarIcon"/></a></span>
                    <a i18n="@@logout" href (click)="logoutCustomer()"><span class="title">Logout</span>  </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </nav>

    <div class="page-container ">
        
        <div class="header">
            <a href (click)="homeCustomer()" class="headerIcon pull-left visible-xs"><img src="assets/img/home.png" class="headerIcon" /></a>
            <div class="">
                <div class="brand inline m-l-10 ">
                    <img src="assets/img/logo.png" alt="logo" class="logoImage">
                </div>
            </div>
            <a href (click)="logoutCustomer()" class="headerIcon pull-right visible-xs"><img src="assets/img/LogoutIconBlack.png" class="headerIcon" /></a>
        </div>
        <div class="page-content-wrapper ">
            <div class="content ">
                <div class=" container-fluid container-fixed-lg">
                    <ol class="breadcrumb">
                        <li i18n="@@select-claim" class="breadcrumb-item"><a href="/">Select Repair Request</a></li>
                        <li i18n="@@claim-detail" class="breadcrumb-item active">Repair Request Details</li>
                    </ol>
                </div>
                <div class=" container-fluid   container-fixed-lg">
                    <div class="row m-b-10 m-t-20">
                        <div class="col-lg-4 sm-p-b-10 md-p-b-10">
                            <div class="widget-13 card no-border  no-margin widget-loader-circle  hidden-xs  bg-gray">
                                <div class="container-sm-height no-overflow">
                                    <div class="card-header  ">
                                        <div i18n="@@claim-information" class="card-title">Repair Request Information </div>
                                    </div>
                                    <div class="card-block">
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@reported-date" class="hint-text all-caps font-montserrat small no-margin ">Request Reported Date</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.createdTime | date:'dd-MMMM-yyyy' }}</p>
                                        <hr>
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@contract-type" class="hint-text all-caps font-montserrat small no-margin ">Contract Type</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.policyType }}</p>
                                        <hr>
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@mobile-no" class="hint-text all-caps font-montserrat small no-margin ">Mobile number</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.phoneNumber }}</p>
                                        <hr>
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@incident-date" class="hint-text all-caps font-montserrat small no-margin ">Incident date</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.incidentDate | date:'dd-MMMM-yyyy' }}</p>
                                        <hr>
                                        <!--img src="assets/img/claims.png" class="claimInfoIcon">
						  <p class="hint-text all-caps font-montserrat small no-margin ">Request Type</p>
						  <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.damageType}}</p>	
						  <hr>
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@claim-no" class="hint-text all-caps font-montserrat small no-margin ">Claim Number</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaimNumber }}</p>
                                        <hr> -->
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@incident-location" class="hint-text all-caps font-montserrat small no-margin ">Incident location</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.incidentHappenAddress }}</p>
                                        <!--<hr>
                                        <img src="assets/img/claims.png" class="claimInfoIcon">
                                        <p i18n="@@request-status" class="hint-text all-caps font-montserrat small no-margin ">Request status</p>
                                        <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.status }}</p>
                                        <br/> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-group horizontal  visible-xs  bg-gray" id="accordion" role="tablist">
                                <div class="card card-default m-b-0">
                                    <div class="card-header " role="tab" id="headingThree">
                                        <h4 class="card-title">
                                            <a i18n="@@claim-information" class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								   Claim Information
								</a>
                                        </h4>
                                    </div>
                                    <div id="collapseThree" class="collapse" role="tabcard" aria-labelledby="headingThree">
                                        <div class="card-block">
                                            <div class="card-block">
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@reported-date" class="hint-text all-caps font-montserrat small no-margin ">Request Reported Date</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.createdTime | date:'dd-MMMM-yyyy' }}</p>
                                                <hr>
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@contract-type" class="hint-text all-caps font-montserrat small no-margin ">Contract Type</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.policyType }}</p>
                                                <hr>
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@mobile-no" class="hint-text all-caps font-montserrat small no-margin ">Mobile number</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.phoneNumber }}</p>
                                                <hr>
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@incident-date" class="hint-text all-caps font-montserrat small no-margin ">Incident date</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.incidentDate | date:'dd-MMMM-yyyy' }}</p>
                                                <hr>
                                                <!--img src="assets/img/claims.png" class="claimInfoIcon">
								  <p class="hint-text all-caps font-montserrat small no-margin ">Request Type</p>
								  <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaim.damageType}}</p>	
								  <hr-->
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@claim-no" class="hint-text all-caps font-montserrat small no-margin ">Claim Number</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedClaimNumber }}</p>
                                                <hr>
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@incident-location" class="hint-text all-caps font-montserrat small no-margin ">Incident location</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.incidentHappenAddress }}</p>
                                                <hr>
                                                <img src="assets/img/claims.png" class="claimInfoIcon">
                                                <p i18n="@@request-status" class="hint-text all-caps font-montserrat small no-margin ">Request status</p>
                                                <p class="all-caps font-montserrat  no-margin text-primary ">{{ selectedCustomer.status }}</p>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END WIDGET -->
                        </div>
                        <div class="col-lg-8">
                            <!-- START WIDGET widget_realtimeWidget-->
                            <div id="rootwizard" class="">
                                <!-- Nav tabs -->
                                <div class="row bg-gray">
                                    <ul class="nav nav-tabs nav-tabs-linetriangle nav-tabs-separator hidden-xs" role="tablist" data-init-reponsive-tabs="dropdownfx">
                                        <li class="nav-item tab-20">
                                            <a i18n="@@pickup-add" [ngClass]="(wizardselectedCnt=='1')?'active':''" href="#" role="tab">
                                                <!--img src="assets/img/wizard_icon_1.png" class="wizard-icon"/--><span class="wizard_tab_text">Pickup<br/>Address</span></a>
                                        </li>
                                        <li class="nav-item tab-20">
                                            <a i18n="@@drop-add" [ngClass]="(wizardselectedCnt=='2')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Drop<br/>Address</span></a>
                                        </li>
                                        <li class="nav-item tab-20">
                                            <a i18n="@@device-detail" [ngClass]="(wizardselectedCnt=='3')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Device<br/>Details</span></a>
                                        </li>
                                        <!-- <li class="nav-item tab-20">
                                            <a i18n="@@upload-doc" [ngClass]="(wizardselectedCnt=='4')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Upload<br/>Document</span></a>
                                        </li> -->
                                        <li class="nav-item tab-20">
                                            <a i18n="@@service-fee" [ngClass]="(wizardselectedCnt=='4')?'active':''" href="#" role="tab"> <span class="wizard_tab_text">Service<br/>Fee</span></a>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-tabs nav-tabs-simple nav-tabs-separator visible-xs" role="tablist" data-init-reponsive-tabs="dropdownfx">
                                        <li class="nav-item tab-20">
                                            <a i18n="@@pickup-add" [ngClass]="(wizardselectedCnt=='1')?'active':''" href="#" role="tab">
                                                <!--img src="assets/img/wizard_icon_1.png" class="wizard-icon"/--><span class="wizard_tab_text">Pickup<br/>Address</span></a>
                                        </li>
                                        <li class="nav-item tab-20">
                                            <a i18n="@@drop-add" [ngClass]="(wizardselectedCnt=='2')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Drop<br/>Address</span></a>
                                        </li>
                                        <li class="nav-item tab-20">
                                            <a i18n="@@device-detail" [ngClass]="(wizardselectedCnt=='3')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Device<br/>Details</span></a>
                                        </li>
                                        <!-- <li class="nav-item tab-20">
                                            <a i18n="@@upload-doc" [ngClass]="(wizardselectedCnt=='4')?'active':''" href="#" role="tab"><span class="wizard_tab_text">Upload<br/>Document</span></a>
                                        </li> -->
                                        <li class="nav-item tab-20">
                                            <a i18n="@@service-fee" [ngClass]="(wizardselectedCnt=='4')?'active':''" href="#" role="tab"> <span class="wizard_tab_text">Service<br/>Fee</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Tab panes -->

                                <div class="tab-content bg-white">
                                    <div class="tab-pane padding-20 sm-no-padding active slide-left" id="tab1" *ngIf="wizardselectedCnt==1">
                                        <div class="row row-same-height">
                                            <div class="col-md-4 b-r b-dashed b-grey sm-b-b">
                                                <div class="sm-padding-5 sm-m-t-15">
                                                    <div class="text-center">
                                                        <h4 i18n="@@pickup-add"><b>Pickup Address</b></h4>
                                                        <img src="assets/img/step1.png" class="imgWizard">
                                                        <p i18n="@@pickup-long" class="p-t-20 keepWord">Please provide your Pickup address from where our team can pickup the device.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sm-padding-5">
                                                    <div class="form-group-attached">
                                                        <div class="form-group form-group-default required">
                                                            <label i18n="@@flat-house">Flat/House No/Floor/Building</label>
                                                            <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.pickupUnit" name="pickupUnit" [placeholder]="'Unit'" [disabled]="false" data_field="House No" maxlength="100">
                                                        </div>
                                                        <div class="form-group form-group-default required">
                                                            <label i18n="@@colony-street">Colony/ Street/ Locality</label>
                                                            <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.pickupArea" name="pickupArea" [placeholder]="'Area'" [disabled]="false" data_field="Area" maxlength="100">
                                                        </div>
                                                        <div class="row clearfix">
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@country">Country</label>
                                                                    <select class="full-width m-t-2 required-input" [(ngModel)]="selectedClaim.country" name="country" data_field="Country">
                                                                        <option i18n="@@select" value="">Select</option>
                                                                        <option i18n="@@india" value="India" selected>India</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@state">State</label>
                                                                    <select class="full-width m-t-2 required-input" [(ngModel)]="selectedClaim.pickupState" name="pickupState" data_field="State">
                                                                        <option i18n="@@select" value="">Select</option>
                                                                        <option *ngFor="let state of stateOptions" [value]="state">{{state}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row clearfix">
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@city">City</label>
                                                                    <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.pickupCity" name="pickupCity" [placeholder]="'City'" [disabled]="false" data_field="City">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@pincode">Pincode</label>
                                                                    <input class="form-control required-input" [type]="'number'" [(ngModel)]="selectedClaim.pinCode" name="pinCode" [placeholder]="'Pincode'" [disabled]="false" maxlength="6" dataType="Pincode" data_field="Pincode">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane slide-left padding-20 sm-no-padding active" id="tab2" *ngIf="wizardselectedCnt==2">
                                        <div class="row row-same-height">
                                            <div class="col-md-4 b-r b-dashed b-grey sm-b-b">
                                                <div class="sm-padding-5 sm-m-t-15">
                                                    <div class="text-center">
                                                        <h4 i18n="@@drop-add"><b>Drop Address</b></h4>
                                                        <img src="assets/img/step1.png" class="imgWizard">
                                                        <p i18n="@@drop-long" class="p-t-20 keepWord">Please provide your Drop address where our team can deliver the device.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sm-padding-5">
                                                    <div class="form-group-attached">
                                                        <div class="row clearfix">
                                                            <div class="pull-left">
                                                                <div class="checkbox check-primary  ">
                                                                    <input type="checkbox" id="checkbox-agree" [(ngModel)]="isPickupAddressSameAsDispatchAddress" (change)="onSelectPickupSameAsDispatch($isPickupAddressSameAsDispatchAddress ? 'yes' : 'no')" [checked]="isPickupAddressSameAsDispatchAddress" />
                                                                    <label i18n="@@pick-drop-same" for="checkbox-agree">Is your Drop Address same as your Pickup address</label>
                                                                </div>
                                                            </div>
                                                            <br>
                                                        </div>
                                                        <div class="form-group form-group-default required">
                                                            <label i18n="@@flat-house">Flat/House No/Floor/Building</label>
                                                            <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.dispatchUnit" name="dispatchUnit" [placeholder]="'Unit'" [disabled]="false" data_field="House No" maxlength="100">
                                                        </div>
                                                        <div class="form-group form-group-default required">
                                                            <label i18n="@@colony-street">Colony/ Street/ Locality</label>
                                                            <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.dispatchArea" name="dispatchArea" [placeholder]="'Area'" [disabled]="false" data_field="Area" maxlength="100">
                                                        </div>
                                                        <div class="row clearfix">
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@country">Country</label>
                                                                    <select class="full-width m-t-2 required-input" [(ngModel)]="selectedClaim.dispatchCountry" name="dispatchCountry" data_field="Country">
                                                                        <option i18n="@@select" value="">Select</option>
                                                                        <option i18n="@@india" value="India" selected>India</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@state">State</label>
                                                                    <select class="full-width m-t-2 required-input" [(ngModel)]="selectedClaim.dispatchState" name="dispatchState" data_field="State">
                                                                        <option i18n="@@select" value="">Select</option>
                                                                        <option *ngFor="let state of stateOptions" [value]="state">{{state}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row clearfix">
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@city">City</label>
                                                                    <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.dispatchCity" name="dispatchCity" [placeholder]="'City'" [disabled]="false" data_field="City">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@pincode">Pincode</label>
                                                                    <input class="form-control required-input" [type]="'number'" [(ngModel)]="selectedClaim.dispatchPinCode" name="dispatchPinCode" [placeholder]="'Pincode'" [disabled]="false" dataType="Pincode" data_field="Pincode">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane slide-left padding-20 sm-no-padding active" id="tab3" *ngIf="wizardselectedCnt==3">
                                        <div class="row row-same-height">
                                            <div class="col-md-4 b-r b-dashed b-grey sm-b-b">
                                                <div class="sm-padding-5 sm-m-t-15">
                                                    <div class="text-center">
                                                        <!--h4><b>Device Details</b></h4>
							<img src="assets/img/step3.png" class="imgWizard">
							<p class="p-t-20 keepWord">Please fill out your device details here.<br/>Provide the details of the device for which you are requesting for the claim.</p-->
                                                        <h4 i18n="@@unlock-pattern"><b>Unlock pattern</b></h4>
                                                        <img style="height: 100% !important; width: 120px !important;" class="imgWizard" src="assets/img/step3-new.png">
                                                        <p i18n="@@pattern-eg" class="p-t-20 keepWord">Example: For the above pattern, the numbers would be <b>3 2 1 4 5 6 9 8 7</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sm-padding-5">
                                                    <div class="form-group-attached">
                                                        <div class="row clearfix">
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default required">
                                                                    <label i18n="@@device-color">Device Color</label>
                                                                    <input class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.colorOfDevice" name="colorOfDevice" [placeholder]="'Color'" [disabled]="false" data_field="Color">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group form-group-default  required">
                                                                    <label i18n="@@memory">Memory</label>
                                                                    <input type="text" class="form-control required-input" [type]="'text'" [(ngModel)]="selectedClaim.memorycapacity" name="memorycapacity" [placeholder]="'Capacity'" [disabled]="false" data_field="Memory">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row clearfix" style="border-bottom:1px solid #f2f2f2">
                                                            <div class="col-sm-12">
                                                                <div class="form-group form-group-default">
                                                                    <label i18n="@@pin-pass" class="">PIN/Password</label>
                                                                    <!--select class="full-width m-t-2" [(ngModel)]="selectedClaim.isPhoneLocked"  (change)="onSelectPin($event.target.value)"-->
                                                                    <select class="mid-width m-t-2" [(ngModel)]="selectedClaim.isPhoneLocked">
                                                                        <option i18n="@@select" value="No">Select</option>
                                                                        <option i18n="@@pin" value="pin">PIN</option>
                                                                        <option i18n="@@password" value="password">Password</option>
                                                                        <option i18n="@@pattern" value="pattern">Pattern</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row clearfix">
                                                            <div class="col-sm-12" *ngIf="selectedClaim.isPhoneLocked=='pin' || selectedClaim.isPhoneLocked=='password' || selectedClaim.isPhoneLocked=='pattern'">
                                                                <div class="form-group form-group-default">
                                                                    <label i18n="@@enter-password">Enter the Password</label>
                                                                    <input class="form-control" [type]="'text'" [(ngModel)]="selectedClaim.lockCodeDeacription" name="lockCodeDeacription" [placeholder]="'Pin/Pattern/Password'" [disabled]="false" data_field="Pin/Password">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="tab-pane slide-left padding-20 sm-no-padding active" id="tab4" *ngIf="wizardselectedCnt==4">
                                        <div class="row row-same-height">
                                            <div class="col-md-4 b-r b-dashed b-grey sm-b-b">
                                                <div class="sm-padding-5 sm-m-t-15">
                                                    <div class="text-center">
                                                        <h4 i18n="@@document-detail"><b>Document Details</b></h4>
                                                        <img src="assets/img/step4.png" class="imgWizard">
                                                        <p i18n="@@upload doc" class="p-t-20 keepWord">Please upload the required documents. Make sure the documents are clear and size is less than 1 MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sm-padding-5">
                                                    <div class="row clearfix">
                                                        <div class="col-md-12" *ngFor="let item of claimDocument;let i = index" style="border-bottom:1px dotted #f0f0f0;padding:15px 20px;">
                                                            <div class="form-group  required">
                                                                <label [class]="item.mandatory ?'control-label required' : 'control-label'">
										  {{item.documentType}} (File size <= 1MB)<br/> Allowed File Type -  JPG,JPEG,PNG,PDF</label>
                                                                <div class="pull-right">
                                                                    <span class="label label-success" *ngIf="item.status == 'APPROVED'">
											  <i class="fa fa-check-circle"></i> Approved
											</span>
                                                                    <span class="label label-danger" *ngIf="item.status != 'APPROVED' && item.status != ''">{{item.status}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="!item.status || item.status.toUpperCase() == 'PENDING' || item.status.toUpperCase() =='REJECTED'">
                                                                <div class="form-group form-group-default" *ngIf="!item.userFileName || item.status.toUpperCase() =='REJECTED'">
                                                                    <input [id]="'file-upload' + item.documentName" type="file" (change)=onFileChangeUpload($event,i) data_field="File Input" [class]="item.mandatory ?'form-control required-input' : 'form-control'" dataType="File" [name]="item.documentType" accept=".JPG,.JPEG,.PNG,.PDF">
                                                                </div>
                                                                <div class="form-group form-group-default" *ngIf="item.userFileName">
                                                                    <p>Uploaded - {{ item.userFileName }} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="tab-pane slide-left padding-20 sm-no-padding active" id="tab4" *ngIf="wizardselectedCnt==4">
                                        <div class="row row-same-height">
                                            <div class="col-md-4 b-r b-dashed b-grey sm-b-b">
                                                <div class="sm-padding-5 sm-m-t-15">
                                                    <div class="text-center" *ngIf="this.showPayment">
                                                        <h4 i18n="@@service-fee-info"><b>Service Fee Information</b></h4>
                                                        <img src="assets/img/step5.png" class="imgWizard">
                                                        <p i18n="@@payment-method">Please select a payment method.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sm-padding-5" *ngIf="this.showPayment">
                                                    <div class="row paymentWaysBlock">
                                                        <div class="col-xs-12 col-sm-12" *ngFor="let method of paymentMethodOptions">
                                                            <label class="radioContainer regular-radio">
									<input type="radio" [id]="'chk_paymentmethod'"
                                                [(ngModel)]="paymentMethod"
                                                [value]="method.key"
                                                [ngModelOptions]="{standalone: true}"
                                                name="paymentMethod"
                                                (change)="onPaymentMethodSelection(paymentMethod)" />
									<i></i> <span class="keepWord">{{ method.key }}</span>
									<p>{{method.value}}</p>									
								</label>
                                                        </div>
                                                    </div>
                                                    <!--form role="form"-->
                                                    <div class="form-group-attached">
                                                        <div class="bg-master-lightest padding-30 b-rad-lg" *ngIf="paymentDocument">
                                                            <h5 class="pull-left no-margin">{{paymentDocument.documentType}}</h5>
                                                            <span class="label label-success pull-right" *ngIf="paymentDocument.status == 'APPROVED'">APPROVED</span>
                                                            <span class="label label-danger pull-right" *ngIf="paymentDocument.status != 'APPROVED'">{{paymentDocument.status}}</span>
                                                            <div class="clearfix"></div>
                                                            <div *ngFor="let payment of selectedCustomer.payments">Service Fee Amount - <i class="fa fa-inr" aria-hidden="true"></i> {{ payment.paymentAmount }}</div>
                                                            <div class="form-group form-group-default required m-t-20" *ngIf="!paymentDocument.status || paymentDocument.status.toUpperCase() == 'PENDING' || paymentDocument.status.toUpperCase() =='REJECTED'">
                                                                <label [attr.for]="'file-upload' + paymentDocument.documentName" class="custom-file-upload"> Upload {{ paymentDocument.description }} (File size <= 1 MB) <br/> Allowed File Type -  JPG,JPEG,PNG,PDF</label>
                                                                <input [class]="paymentDocument.mandatory ?'form-control required-input' : 'form-control'" [id]="'file-upload' + paymentDocument.documentName" type="file" (change)=onPaymentSlipFileChange($event) dataType="File" [name]="paymentDocument.documentType" accept=".JPG,.JPEG,.PNG,.PDF">
                                                                <span>{{ paymentDocument.userFileName }}</span>
                                                            </div>
                                                        </div>
                                                        <div #paymentextra class="payment-detail" *ngIf="paymentMethod != null && paymentMethod == 'PAY_ONLINE'">
                                                            <div class="col-xs-12 col-sm-12 col-md-12" *ngFor="let payment of selectedCustomer.payments">
                                                                <div class="payextra">
                                                                    <div class="col-sm-2 payextra-media"><img src="assets/img/icon.png"></div>
                                                                    <div class="col-sm-10 payextra-content">
                                                                        <h5 i18n="@@service-fee-amt">Service Fee Amount
                                                                            <div class="pull-right">
                                                                                <span class="" *ngIf="payment.paymentStatus == 'PAID' else failed">Paid</span>
                                                                                <ng-template #failed><span class="">Pending</span> </ng-template>
                                                                            </div>
                                                                        </h5>
                                                                        <span><i class="fa fa-inr" aria-hidden="true"></i> {{ payment.paymentAmount }}</span><span i18n="@@pay-now" class="btn btn-xs btn-primary pull-right" *ngIf="!payment.paymentStatus || payment.paymentStatus != 'PAID'"
                                                                            (click)="razorPaymentOrder(payment.paymentAmount)">Pay Now</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <!--/form-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">	
				</div-->

                                    <div *ngIf="errorMessage!=null" class="clearfix text-center text-danger">
                                        {{errorMessage}}
                                    </div>

                                    <div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
                                        <ul class="pager wizard no-style">
                                            <li class="next" *ngIf="isNextBtnEnabled">
                                                <button [disabled]="nextDisable" class="btn btn-primary btn-cons pull-right" type="button" (click)="nextPrev(1)">
                        <span>Next</span>
                      </button>
                                            </li>
                                            <li class="next finish" *ngIf="isSubmitBtnEnabled">
                                                <button [disabled]="nextDisable" class="btn btn-primary btn-cons pull-right" type="button" (click)="nextLast(2)">
                        <span i18n="@@finish">Finish</span>
                      </button>
                                            </li>
                                            <li class="next finish " *ngIf="isDocumentSubmitBtnEnabled">
                                                <button class="btn btn-primary btn-cons pull-right" type="button" (click)="nextLast(1)">
                        <span i18n="@@finish">Finish</span>
                      </button>
                                            </li>
                                            <li class="previous" *ngIf="isPreviousBtnEnabled">
                                                <button class="btn btn-default btn-cons pull-right" type="button" (click)="nextPrev(-1)">
                        <span i18n="@@previous">Previous</span>
                      </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END CONTAINER FLUID -->
            </div>
            <!-- END PAGE CONTENT -->

            <!-- START COPYRIGHT -->
            <!-- START CONTAINER FLUID -->
            <div class=" container-fluid  container-fixed-lg footer">
                <div class="copyright sm-text-center">
                    <p class="small no-margin pull-left sm-pull-reset">
                        <span i18n="@@copy-foot" class="hint-text">Copyright © 2020 | </span>
                        <span class="sm-block"><a i18n="@@terms" href="#" class="m-l-10 m-r-10">Terms of use</a> <span class="muted">|</span> <a i18n="@@privacy" href="#" class="m-l-10">Privacy Policy</a></span>
                    </p>
                    <p i18n="@@copy-text-foot" class="small no-margin pull-right sm-pull-reset"><span class="hint-text">AmTrust Mobile Solutions India Pvt. Ltd.</span>
                    </p>
                    <div class="clearfix"></div>
                </div>
            </div>
            <!-- END COPYRIGHT -->

            <!-- load modal -->
            <div *ngIf="showModal">
                <div class="modal-mask" id="paymentsuccessmodal">
                    <div class="modal-dialog">
                        <div class="modal-content-wrapper">
                            <div class="modal-content">
                                <div class="modal-body m-t-b-15" align="center">
                                    <h3 i18n="@@payment-confirm" class="text-center"><b>Payment Confirmation</b></h3>
                                    <img src="assets/img/successful.png" class="successImage" />
                                    <h3 i18n="@@payment-success" class="text-center m-t-10"><b>Payment Successful</b></h3>
                                    <a i18n="@@my-claims" href="/" class="btn btn-primary m-t-15">My Claims</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showFailedModal">
                <div class="modal-mask">
                    <div class="modal-dialog">
                        <div class="modal-content-wrapper">
                            <div class="modal-content">
                                <div class="modal-body m-t-b-15" align="center">
                                    <h3 i18n="@@payment-fail" class="text-center"><b>Payment Failed</b></h3>
                                    <img src="assets/img/successful.png" class="successImage" />
                                    <h3 i18n="@@payment-trans-fail" class="text-center m-t-10"><b>Payment transaction failed. Please try again later.</b></h3>
                                    <a i18n="@@my-claims" href="/" class="btn btn-primary m-t-15">My Claims</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showDocumentModal">
                <div class="modal-mask">
                    <div class="modal-dialog">
                        <div class="modal-content-wrapper">
                            <div class="modal-content">
                                <div class="modal-body m-t-b-15" align="center">
                                    <h3 i18n="@@request-confirm" class="text-center"><b>Request Confirmation</b></h3>
                                    <img src="assets/img/successful.png" class="successImage" />
                                    <h5 i18n="@@sucess-thanks-long" class="text-center m-t-10"><b>Thank you for providing the required details, we will keep you updated on the progress of your repair request.</b></h5>
                                    <a i18n="@@my-claims" href="/" class="btn btn-primary m-t-15">My Claims</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngx-spinner bdColor="rgba(109,94,172,0.5)" color="#fff" type="line-spin-clockwise-fade">&nbsp;</ngx-spinner>
        </div>
        <!-- END PAGE CONTENT WRAPPER -->
    </div>
    <!-- END PAGE CONTAINER -->
</div>