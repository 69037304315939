<div class="login-wrapper ">
    <div class="bg-pic">
        <img src="assets/img/bg_login.png" alt="" class="lazy">
    </div>
    <div class="login-container bg-white">
        <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-25 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src="assets/img/logo.png" alt="logo" class="logoImage">
            <p class="p-t-35">Select Language / भाषा चुने</p>
            <ng-container *ngFor="let language of languageList">
                <!-- <a href="/{{language.code}}/"> <button class="button">{{language.label}}</button> </a> -->
                <input type="radio" [name]="language.code" [(ngModel)]="selectedLanguage" [value]="language.code" (change)="openPage(language.code)">{{language.label}}<br>
            </ng-container>
            <!-- <input type="radio" [(ngModel)]="language" value="en">English -->
            <!-- <input type="radio" [(ngModel)]="language" value="hi">Hindi -->
            <p i18n="@@sign-in" class="p-t-15">Sign into your account</p>
            <form id="form-login" class="p-t-15" role="form" action="index.html">
                <div class="form-group form-group-default">
                    <label i18n="@@mobile-no">Mobile Number</label>
                    <div class="controls">
                        <input id="signupEmail" required type="email" maxlength="100" class="form-control" [(ngModel)]="mobile" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <div *ngIf="otpSent else otppart">
                    <div class="form-group form-group-default" style="margin-bottom: 0px;">
                        <label i18n="@@otp">OTP</label>
                        <div class="controls">
                            <input id="signupPassword" required type="password" maxlength="25" class="form-control" length="40" [(ngModel)]="otp" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <a (click)="sendOtpToCustomer();" class="cursor-pointer" [ngClass]="{'is-disabled':isResendOtpDisabled}">Resend OTP <span *ngIf="resendOtpTimeLeft">( {{resendOtpTimeLeft}} )</span></a>
                    <!-- <a href="">Resent OTP</a> -->
                    <div *ngIf="errorMessage!=null">
                        <label class="error">{{ errorMessage }}</label>
                    </div>

                    <div class="m-login__form-action">
                        <button i18n="@@verify-otp" (click)="verifyCustomerOtp()" id="m_login_signin_submit" class="btn btn-primary btn-cons m-t-10">Verify Otp</button>
                    </div>
                </div>
                <ng-template #otppart>
                    <div *ngIf="errorMessage!=null">
                        <label class="error">{{ errorMessage }}</label>
                    </div>
                    <div class="m-login__form-action">
                        <button i18n="@@send-otp" (click)="sendOtpToCustomer()" id="m_login_signin_submit" class="btn btn-primary btn-cons m-t-10">Send OTP</button>
                    </div>
                </ng-template>

            </form>
            <div class="pull-bottom sm-pull-bottom">
                <div class="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
                    <div class="col-sm-12 no-padding m-t-10">
                        <p i18n="@@copyright">
                            <small>Copyright © 2020 | AmTrust Mobile Solutions India Pvt. Ltd.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>