export class Profile {
    name: string;
    email: string;
    phoneNumber: string;
    country: string;
    age: number;
    amount: string;
    redirectUrl: string;
    phone: string;
    purpose: string;
    functionInstanceName: string;
    emailToBeSend: boolean;

    constructor(){
        this.name=null;
        this.email=null;
        this.phoneNumber=null;
        this.phone=null;
        this.country=null;
        this.age=null;
        this.amount=null;
        this.redirectUrl=null;
        this.purpose=null;
        this.functionInstanceName = "AMTRUST_INSTAMOJO_CRED"
        this.emailToBeSend = false;
    }
}