import { Claim } from "./claim.model";

export class Payment {
    paymentAmount: string;
    paymentStatus: string;
    paidAmount: string;
    paymentChannel: string;
    transactionNumber: string;
    paymentId:string;
    paymentRequestId:string;
    paidStatus:string;
    purpose:string;
    paymentDetails: any = {};
    functionInstanceName = "CREATE_ORDER";
    

    constructor() {
        this.paymentAmount = null;
        this.paymentStatus = null;
        this.paidAmount = null;
        this.paymentChannel = null;
        this.transactionNumber=null;
        this.paymentId=null;
        this.paymentRequestId=null;
        this.paidStatus=null;
        this.purpose=null
    }
}

export class RazorPayOrder {

    amount: number;
    currency: string;
    receipt: string;
    payment_capture:  string;
    notes: any;
    

    constructor() {
        this.amount = 0;
        this.currency = null;
        this.receipt = null;
        this.payment_capture = null;
        this.notes = {};
    }
}