var Documents = /** @class */ (function () {
    function Documents() {
        this.status = null;
        this.downloadFileUrl = null;
        this.mandatory = false;
        this.description = null;
        this.fullFileUrl = null;
        this.uploadTimeLong = 0;
        this.fileName = null;
        this.fullDataUrl = null;
        this.documentType = null;
        this.updatedTime = null;
        this.uploadTime = null;
        this.url = null;
        this.documentName = null;
        this.allowedFileTypes = [];
        this.userFileName = null;
        this.createdTime = null;
        this.flowInstanceId = null;
        this.stateInstanceId = null;
        this.companyId = null;
        this.status = null;
        this.downloadFileUrl = null;
        this.mandatory = false;
        this.description = null;
        this.fullFileUrl = null;
        this.uploadTimeLong = 0;
        this.fileName = null;
        this.fullDataUrl = null;
        this.documentType = null;
        this.updatedTime = null;
        this.url = null;
        this.documentName = null;
        this.allowedFileTypes = [];
        this.userFileName = null;
        this.createdTime = null;
        this.flowInstanceId = null;
        this.stateInstanceId = null;
        this.companyId = null;
    }
    return Documents;
}());
export { Documents };
var AWSUploadedDoc = /** @class */ (function () {
    function AWSUploadedDoc() {
        this.Bucket = null;
        this.ETag = null;
        this.Key = null;
        this.Location = null;
    }
    return AWSUploadedDoc;
}());
export { AWSUploadedDoc };
