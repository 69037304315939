import { Documents } from "./documents.model";
import { Payment } from "./payment.model";


export class Customer {

    status: string = null;
    customerFullName: string = null;
    policyId: string = null;
    policyType: string = null;
    entityId: string = null;
    updatedTime: string = null;
    documents: Documents[] = [];
    claimNumber: string = null;
    customerFirstName: string = null;
    companyId: string = null;
    phoneNumber: string = null;
    claimCreatedBy: string = null;
    createdTime: string = null;
    claimCreatedFrom: string = null;
    _id: string = null;
    clientName: string = null;
    payments: Payment[] = [];
    imei: string = null;
    statusCd: string = null;
    dispatchState: string = null;
    deviceLockCode: string = null;
    dispatchPincode: string = null;
    docChaserCounter: number = 0;
    pickupAddress: string = null;
    dispatchCity: string = null;
    pickupUnit: string = null;
    pickupState: string = null;
    pickupCity: string = null;
    dispatchAddress: string = null;
    deviceMemory: string = null;
    deviceLock: string = null;
    dispatchUnit: string = null;
    deviceColor: string = null;
    pickupPincode: string = null;
     
    constructor() {
        this.status = null;
        this.customerFullName = null;
        this.customerFirstName = null;
        this.policyId = null;
        this.updatedTime = null;
        this.documents = []
        this.claimNumber = null;
        this.companyId = null;
        this.phoneNumber = null;
        this.claimCreatedBy = null;
        this.createdTime = null;
        this.claimCreatedFrom = null;
        this._id = null;
        this.clientName = null
        this.payments = [];
        this.imei = null;
        this.statusCd = null;
    }
}