var FileUploadData = /** @class */ (function () {
    function FileUploadData(userFileName, documentName, formData, documentType, errorMsg) {
        this.userFileName = userFileName ? userFileName : null;
        this.documentName = documentName ? documentName : null;
        this.formData = formData ? formData : null;
        this.documentType = documentType ? documentType : null;
        this.errorMsg = errorMsg ? errorMsg : null;
    }
    return FileUploadData;
}());
export { FileUploadData };
