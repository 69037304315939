import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var CustomerSevice = /** @class */ (function () {
    function CustomerSevice(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        });
    }
    CustomerSevice.prototype.getCustomersByPhoneNumber = function (phoneNumber) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.claimcustomer);
        var body = {
            key: {
                phoneNumber: phoneNumber
            },
            operation: "GET_ALL"
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.getClaimsByImei = function (imei, policyId, clearCache) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.claims);
        var body = { "imei": imei, "companyContext": { "companyId": environment.companyId }, clearCache: clearCache };
        if (policyId) {
            body["policyId"] = [policyId];
        }
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.uploadDocument = function (formData) {
        var headers = new HttpHeaders({ 'X-Auth-Token': environment.companyId });
        var url = "" + (environment.apiurl + environment.uploadUrl);
        if (formData) {
            return this.httpClient.post(url, formData, {
                headers: headers,
                observe: 'body',
                reportProgress: true
            });
        }
    };
    CustomerSevice.prototype.updateClaimCustomer = function (customer, updateFlow) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.claimcustomer);
        var body = {
            value: customer,
            operation: "UPDATE",
            entityId: customer.entityId
        };
        if (updateFlow) {
            body["updateFlow"] = updateFlow;
        }
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    CustomerSevice.prototype.updateClaim = function (imei, claimToBeUpdated) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.updateClaim);
        var body = {
            imei: imei,
            values: claimToBeUpdated,
            id: claimToBeUpdated.id
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return CustomerSevice;
}());
export { CustomerSevice };
