import { Claim } from "./claim.model";

export class Documents {
    status: string = null;
    downloadFileUrl: string = null;
    mandatory: boolean = false;
    description: string = null;
    fullFileUrl: string = null;
    uploadTimeLong: number = 0;
    fileName: string = null;
    fullDataUrl: string = null;
    documentType: string = null;
    updatedTime: string = null;
    uploadTime: string = null;
    url: string = null;
    documentName: string = null;
    allowedFileTypes: string[] = [];
    userFileName: string = null;
    createdTime: string = null
    flowInstanceId: string = null;
    stateInstanceId: string = null;
    companyId: string = null;
  
    constructor() {
        this.status = null;
        this.downloadFileUrl = null;
        this.mandatory = false;
        this.description = null;
        this.fullFileUrl = null;
        this.uploadTimeLong = 0;
        this.fileName = null;
        this.fullDataUrl = null;
        this.documentType = null;
        this.updatedTime = null;
        this.url = null;
        this.documentName = null;
        this.allowedFileTypes = [];
        this.userFileName = null;
        this.createdTime = null;
        this.flowInstanceId = null;
        this.stateInstanceId = null;
        this.companyId = null;
    }
}


export class AWSUploadedDoc {
    /* Bucket: "amtrust-customer"
ETag: ""9d9d6b2063667fa205b8f1545544abc2""
Key: "SERCON200608/Photo IDIMG_2524.JPG"
Location: "https://amtrust-customer.s3.ap-south-1.amazonaws.com/SERCON200608/Photo%20IDIMG_2524.JPG"
key
*/
 Bucket : string;
 ETag : string;
 Key : string;
 Location: string;

 constructor(){
     this.Bucket=null;
     this.ETag=null;
     this.Key=null;
     this.Location=null;
 }
}