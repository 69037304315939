import { v4 as uuid } from 'uuid';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { VerifyOtp } from '../../models/verifyotp.model'
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import { HostListener } from '@angular/core';
import { UploadService } from "../../services/upload.service";
import { SaveServices } from "../../services/save.services";
import { DataSharingService, UniversalUser } from '../../services/shared.service'
import { CustomerSevice } from '../../services/customer.service'
import { AWSUploadedDoc, Documents } from '../../models/documents.model'
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from '../../models/profile.model';
import { Customer } from '../../models/customer.model';
import { User } from '../../models/user.model';
import { Claim } from '../../models/claim.model';
import { FileUploadData } from '../../models/fileupload.model';
import { timingSafeEqual } from 'crypto';
import { Payment, RazorPayOrder } from '../../models/payment.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var Razorpay: any;
declare var $ :any;

declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  @ViewChild('paymentextra') myDiv: ElementRef<HTMLElement>;


  customers: Customer[];
  customersFiltered: Customer[];
  selectedCustomer: Customer;
  selectedClaimNumber: string = null;
  phoneNumber: string = null;
  selectedClaim: Claim;
  verifyOtp: VerifyOtp;
  awsUploadedDoc: AWSUploadedDoc;
  payModel = new Profile();
  tempDocument: Documents;
  paymentDocument: Documents;
  fileUploadData: FileUploadData;
  previousDocuments: Documents[];
  nextDocumnets: Documents[];
  claimDocument: Documents[];
  uploadFileDocument: any;//Documents[];
  uploadFileTempDocument: any;
  lastDocument: Documents;
  submitDocuments: Documents[];
  errorMessage: string = null;
  prevErrorMsg: string = null;
  showPayment: boolean = false;
  isPickupAddressSameAsDispatchAddress: boolean = false;
  addressSubmitted: boolean = false;
  paymentMethod: string;
  paymentMethodOptions: any;
  DOCUMENT_TYPE_HDFC_PAYMENT_SLIP = "HDFC Payment Slip";
  modalRef: BsModalRef;
  modalRefCheckout: BsModalRef;
  wizardselectedCnt: any;
  isPreviousBtnEnabled: boolean = false;
  isNextBtnEnabled: boolean = true;
  isSubmitBtnEnabled: boolean = false;
  isDocumentSubmitBtnEnabled: boolean = false;
  showModal: boolean = false;
  showFailedModal: boolean = false;
  showDocumentModal: boolean = false;
  stateOptions: any;
  passwordFormatted: string = null;
  nextDisable: boolean = false;

  test: string;
  constructor(
    public router: Router, public route: ActivatedRoute,
    public uploadService: UploadService,
    public spinner: NgxSpinnerService,
    public saveService: SaveServices,
    private dataSharingService: DataSharingService,
    private universalUser: UniversalUser,
    private customerService: CustomerSevice,
    private modalService: BsModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.verifyOtp = new VerifyOtp();
    this.selectedCustomer = null;
    this.selectedClaim = null;
    this.customers = [];
    this.customersFiltered = [];
    this.tempDocument = null;
    this.fileUploadData = null;
    this.previousDocuments = [];
    this.nextDocumnets = [];
    this.claimDocument = [];
    this.uploadFileDocument = [];
    this.uploadFileTempDocument = [];
    this.submitDocuments = [];
    this.lastDocument = null;
    this.wizardselectedCnt = 1;

    this.paymentMethodOptions = [
      {
        key: "PAY_ONLINE",
        value: "Pay Online using Debit/ Credit card/ Net Banking/ UPI"
      },
      {
        key: "PAY_AT_HDFC",
        value: "Pay at HDFC Bank Branch"
      }
    ];
    this.stateOptions = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Daman & Diu','Delhi','Goa','Gujarat ','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Orissa','Pondicherry','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
    this.paymentDocument = null;
  }
  paymentId: string;
  orderId: string;
  paymentStatus = "";
  paymentRequestId: string;

  ngOnInit() {
    //console.log(this.route.snapshot);
    //console.log("*********** shared object **************");
    //console.log(this.dataSharingService.getSharedObject());
    //this.showPaymentSuccessModal(); 
    this.paymentId = this.route.snapshot.queryParams["payment_id"];
    this.paymentStatus = this.route.snapshot.queryParams["payment_status"];
    this.paymentRequestId = this.route.snapshot.queryParams["payment_request_id"];
    this.phoneNumber = this.route.snapshot.params["phoneNumber"];
    this.selectedClaimNumber = this.route.snapshot.params["claimNumber"];
    //console.log("payment status :: " + this.paymentStatus);

    /*
    if (this.paymentStatus ) {
      console.log("user");
        console.log(this.universalUser.getUser());
        var phoneNumber: string = null;
        if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
          phoneNumber = this.universalUser.getUser().phoneNumber;
        }
        else {
          phoneNumber = this.phoneNumber;
        }
        if (this.paymentStatus == "Failed") {
          this.errorMessage = "Paymnet transaction failed. Please try again later"
          //this.getCustomersByPhoneNumber(phoneNumber);
      this.showPaymentFailedModal();
        }
        if (this.paymentStatus == "Credit") {
          //this.getCustomersByPhoneNumber(phoneNumber, true);
      this.showPaymentSuccessModal();
        }
    }*/

    if (this.paymentStatus) {
      //console.log("user");
      //console.log(this.universalUser.getUser());
      var phoneNumber: string = null;
      if (this.universalUser.getUser() && this.universalUser.getUser().phoneNumber) {
        phoneNumber = this.universalUser.getUser().phoneNumber;
      }
      else {
        phoneNumber = this.phoneNumber;
      }
      if (this.paymentStatus == "Failed") {
        this.errorMessage = "Payment transaction failed. Please try again later"
        this.getCustomersByPhoneNumber(phoneNumber);
      }
      if (this.paymentStatus == "Credit") {
        this.getCustomersByPhoneNumber(phoneNumber, true);
      }
    }

    this.customers = JSON.parse(JSON.stringify(this.dataSharingService.getSharedObject()));


    if (this.customers != null && this.customers.length > 0) {
      //this.verifyOtp.customer = this.customers[0];
      this.setCustomer();
    }
    else {
      const user: User = this.universalUser.getUser();
      if (user != null) {
        // get customers from phone number
        this.getCustomersByPhoneNumber(user.phoneNumber);

      }
    }


    //   if (this.paymentStatus != null && this.paymentStatus =='Credit') {
    //    console.log(this.payment);
    //    this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
    //    this.savepayment();
    //   }
    //   else{
    //   console.log('--else-init---')
    //   console.log(this.verifyOtp);
    //   //this.verifyOtp=JSON.parse(sessionStorage.getItem("verifyOtp"))
    //   console.log('2 init---')
    //   console.log(this.verifyOtp);
    //   if (this.verifyOtp==null){
    //     //this.router.navigate(['/pg/login']);
    //   }
    //   else{
    //     for (let pay of this.verifyOtp.customer.payments)
    //     {
    //       if (pay.paymentStatus == 'Credit'){
    //         this.paymentStatus='Credit';
    //       }
    //     }
    //     //console.log(this.verifyOtp.claim.applicableFee);
    //   }
    // }
  }

  ngOnDestroy(): void {
    //sessionStorage.clear();
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    //window.sessionStorage.clear();
  }

  selectedFiles: FileList;

  getCustomersByPhoneNumber(phoneNumber: string, updatePaymnet?: boolean) {
    this.customerService.getCustomersByPhoneNumber(phoneNumber)
      .subscribe(
        response => {
          console.log("getCustomersByPhoneNumber")
          console.log(response)
          if (response && response.success && response.result) {
            this.customers = response.result;

            this.customersFiltered = this.customers.filter(function (claimItem) {
              if (claimItem.hasOwnProperty('statusCd')) {
                return claimItem.statusCd == "ACTIVE";
              }

            });
            if (this.customersFiltered && this.customersFiltered.length == 1) {
              this.selectedCustomer = this.customers[0];
              this.selectedClaimNumber = this.selectedCustomer.claimNumber;
              this.getClaimsByImei();
            }
            console.log(this.customersFiltered);

            if (updatePaymnet) {
              this.setCustomer(updatePaymnet);
              this.showPaymentSuccessModal();
            }
          }
        },
        error => {
          console.log("getCustomersByPhoneNumber error");
          console.log(error);
        }
      )
  }

  setCustomer(updatePayment?: boolean) {
    console.log("setCustomer");
    console.log(this.customers);
    this.customersFiltered = this.customers.filter(function (claimItem) {
      if (claimItem.hasOwnProperty('statusCd')) {
        return claimItem.statusCd == "ACTIVE";
      }

    });
    if (this.customersFiltered && this.customersFiltered.length == 1) {
      this.selectedCustomer = this.customersFiltered[0]
      this.selectedClaimNumber = this.selectedCustomer.claimNumber;
      this.getClaimsByImei();
    }
    if (this.customers && this.customers.length == 1 && this.customers[0].statusCd == "ACTIVE") {
      this.selectedCustomer = this.customers[0];
      this.selectedClaimNumber = this.selectedCustomer.claimNumber;
      this.getClaimsByImei();

    }
    else if (this.customers && this.selectedClaimNumber) {
      const indexc = this.selectedClaimNumber.indexOf("t");
      if (indexc != -1) {
        this.selectedClaimNumber = this.selectedClaimNumber.substring(0, indexc);
      }
      //console.log("selected claim number");
      //console.log(this.selectedClaimNumber);
      for (let cust of this.customers) {
        //console.log(cust.claimNumber);
        if (cust.claimNumber == this.selectedClaimNumber) {
          // const index = this.selectedCustomer.claimNumber.indexOf("t");
          // if (index != -1) {
          //   this.selectedCustomer.claimNumber = this.selectedCustomer.claimNumber.substring(0, index);
          // }

          this.selectedCustomer = cust;

        }
      }
    }
    if (this.selectedCustomer) {
      this.setDocumentsToBeProcess();
    }
    if (updatePayment) {
      //console.log("selected claim")
      //this.getClaimsByImei();
      this.updateCustomerWithPayment();
    }
  }

  updateCustomerWithPayment() {
    if (this.selectedCustomer.payments) {
      var payments: Payment[] = [];
      this.spinner.show();
      for (let paymnet of this.selectedCustomer.payments) {
        if (this.paymentStatus == "Credit") {
          paymnet.paymentStatus = "PAID";
          paymnet.paymentId = this.paymentId;
          paymnet.transactionNumber = this.paymentRequestId;
          paymnet.paymentChannel = "PAY_ONLINE";
          //if (this.selectedClaim == null) {
          
          //}
        }
        if (this.paymentStatus == "Failed") {
          paymnet.paymentStatus = "FAILEd";
        }
        payments.push(paymnet);
      }
      this.selectedCustomer.payments = payments;
      
    }
    this.showPayment = true;
    this.tempDocument = null;
    this.updateCustomer(true);
  }



  paynow(fee) {
    if (fee < 9) {
      fee = 10
    }

    this.payModel.amount = fee;
    this.payModel.phone = this.selectedCustomer.phoneNumber
    this.payModel.email = this.selectedClaim.emailId ? this.selectedClaim.emailId : "sanket@automatapi.com";
    if (window.location.href.indexOf("hi") != -1) {
      this.payModel.redirectUrl = environment.hi_RETURN_URL + "/" + this.selectedCustomer.phoneNumber + "/" + this.selectedClaimNumber;
    }
    else {
      this.payModel.redirectUrl = environment.RETURN_URL + "/" + this.selectedCustomer.phoneNumber + "/" + this.selectedClaimNumber;
    }
    var purpose = ""
    if (this.selectedCustomer.imei) {
      purpose = this.selectedCustomer.imei;
    }
    if (!purpose) {
      purpose = this.selectedCustomer.phoneNumber;
    }
    if (this.selectedClaimNumber) {
      purpose = purpose + "-" + this.selectedClaimNumber;
    }
    this.payModel.purpose = purpose;
    //console.log('Applicable fees to be paid');
    //console.log(fee);
    this.saveService.payNow(this.payModel)
      .subscribe(
        response => {
          //console.log("paynow response");
          //console.log(response);

          if (response.success && response.result && response.result.payment_request && response.result.payment_request.longurl) {
            window.location.href = response.result.payment_request.longurl + "";
          }
          else {
            this.errorMessage = "Something went wrong"
          }
          //window.location.href = response+"";
          // window.open(response+"", "_blank");




        },
        error => {
          console.log("error");
        }
      )


  }

  razorPaymentOrder(fee, doNothing?: boolean) {
    if (doNothing) {
      return;
    }
    this.spinner.show();
    const razorPayOrder = new RazorPayOrder();
    razorPayOrder.amount = fee * 100;
    razorPayOrder.currency = "INR";
    razorPayOrder.payment_capture = "1";
    razorPayOrder.receipt = this.selectedCustomer.claimNumber;
    razorPayOrder.notes = {
      "claimNuber" : this.selectedCustomer.claimNumber,
      "imei" : this.selectedCustomer.imei
    };
    
    this.saveService.razorPayOrder(razorPayOrder)
      .subscribe(
        response => {
          this.spinner.hide();
          if (response && response.success) {
            this.doRazorPayPayment(response.result);
          }
          else if (response && response.error && response.error.message){
            this.errorMessage = response.error.message;
          }
          else {
            this.errorMessage = "Something went wrong. Please try again later";
          }
        },
        error => {
          this.spinner.hide();
        }
      )
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  verifySignature(checkoutResponse: any) {
    //this.errorMessage = "something went wrong";
    setTimeout(() => {
      this.spinner.show();
    }, 100);
    this.saveService.razorPayVerifySignture(checkoutResponse)
      .subscribe(
        response => {
          this.spinner.hide();
          if (!response || !response.success) {
            if (response.error && response.error.message) {
              this.errorMessage = response.error.message;
            }
            else {
              this.errorMessage = "Something went wrong. Please try again later";
            }
            this.showPaymentFailedModal();
          }
          else {
            if (response.success) {
              // remove razorpay div
              let razorPayDiv = document.getElementsByClassName("razorpay-container");
              razorPayDiv[0].remove();
              this.paymentStatus = "Credit";
              this.paymentId = checkoutResponse.razorpay_payment_id;
              this.orderId = checkoutResponse.razorpay_order_id;
              //this.spinner.show();
              //alert("Payment is successfull");
            
              //this.showPaymentSuccessModal();
              //$("#paymentsuccessmodal").modal('show');
              //this.spinner.show();
              this.updateCustomerWithPayment();
              //alert("Wait till payment is done");

            }
            else {
              this.showPaymentFailedModal();
            }
          }
          
        },
        error => {
          this.errorMessage = "Something went wrong. Please try again later";
          this.spinner.hide();
        }
      )
  }

  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
}

  handler(response) {
    console.log("***************");
    console.log(response);
    this.spinner.show();
    this.verifySignature(response);
  }

  doRazorPayPayment(orderResponse: any) {
    var options = {
      "key" : orderResponse.payment_api_key,
      "amount": orderResponse.amount,
      "name": "Amtrust",
      "order_id" : orderResponse.id,
      "description": "Plan payment",
      "notes" : {
        "claimNumber" : this.selectedCustomer.claimNumber,
        "imei" : this.selectedCustomer.imei
      },
      "handler" : this.handler.bind(this),
      // "handler": (function (response) {
      //   console.log(response);
      //   this.spinner.show();
        
      //   // //alert(response.razorpay_payment_id);
      //   // this.showPaymentSuccessModal();
        
      //   //this.showModal = true;
      //   //this.spinner.hide();
      //   console.log(this.showModal);
      //   this.verifySignature(response);
        
        
      // }).bind(this),
      "prefill": {
        "name": this.selectedCustomer.customerFirstName,
        "email": this.selectedClaim.emailId,
        "contact": this.selectedCustomer.phoneNumber,

      },
      "theme": {
        "color": "red"
      }
    };
    var instance = new Razorpay(options);
    //instance.createPayment(options);
    // instance.on('payment.success', function(resp) {
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)});
    instance.open();
    
    
    // instance.on('payment.success', function(resp) {
    //   alert(resp.razorpay_payment_id),
    //   alert(resp.razorpay_order_id),
    //   alert(resp.razorpay_signature)}
    // );
  }

  
  
  // savepayment(){
  //   this.spinner.show();
  //   for(let pay of this.verifyOtp.customer.payments)
  //   {
  //     pay.paymentStatus=this.paymentStatus;
  //     pay.paymentRequestId=this.paymentRequestId;
  //     pay.paidAmount=this.verifyOtp.claim.applicableFee;
  //     pay.paymentId=this.payment;
  //   }
  //   this.saveService.saveDocument(this.verifyOtp.customer);
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 3000);
  //   console.log('Timed out..!!')
  // }
  // upload(uploadName) {
  //   this.spinner.show();
  //   console.log(this.verifyOtp.customer.claimNumber);
  //   const file = this.selectedFiles.item(0);
  //   console.log(file.name);
  //   var fileName=file.name;
  //   var allowedExtns=['jpg','jpeg','png','pdf'];
  //   var ext=fileName.substring(fileName.lastIndexOf('.')+1);
  //   console.log(ext)
  //   if (allowedExtns.indexOf(ext.toLocaleLowerCase()) == -1)
  //   {
  //     console.log('Extension Not Allowed');
  //     this.spinner.hide();
  //     alert('Only jpg/jpeg/png/pdf files are allowed.')
  //     return;
  //   }

  //   this.uploadService.uploadFile(file,this.verifyOtp.customer.claimNumber,uploadName);
  //   for(let doc of this.verifyOtp.customer.documents)
  //   {
  //     if (doc.documentName==uploadName){
  //       doc.downloadFileUrl=environment.AWS_FILE_LOCATION+this.verifyOtp.customer.claimNumber+'/'+uploadName+file.name;
  //       doc.status='Uploaded';
  //       //doc.agentStatus="PENDING";
  //       doc.fileName=file.name;
  //     }
  //   }
  //   this.saveService.saveDocument(this.verifyOtp.customer);
  //   sessionStorage.verifyOtp=JSON.stringify(this.verifyOtp); 
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 3000);
  //   console.log('Timed out..!!')
  //   }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  //select claim number and click on proceed
  submitClaimNumber() {
    //console.log("selected claim number");
    //console.log(this.selectedClaimNumber);
    if (this.customers && this.selectedClaimNumber) {
      for (let cust of this.customers) {
        if (cust.claimNumber && cust.claimNumber == this.selectedClaimNumber) {
          this.selectedCustomer = cust;
          this.getClaimsByImei();
          break;
        }
      }
    }
  }

  isAddressProvided() {
    return this.addressSubmitted && this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
      && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
      && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
  }

  isClaimAddressPresent() {
    return this.selectedClaim && this.selectedClaim.pickupCity && this.selectedClaim.pickupArea && this.selectedClaim.pickupUnit
      && this.selectedClaim.pickupState && this.selectedClaim.dispatchArea && this.selectedClaim.dispatchUnit && this.selectedClaim.dispatchCity
      && this.selectedClaim.dispatchState && this.selectedClaim.dispatchCountry;
  }

  getClaimsByImei(updatePayment?: boolean) {
    this.spinner.show();
    if (this.selectedCustomer != null && this.selectedCustomer.imei != null) {
      this.customerService.getClaimsByImei(this.selectedCustomer.imei, this.selectedCustomer.policyId, true)
        .subscribe(
          response => {
            //console.log("getclaimsbyimei");
            //console.log(response);
            const claims: Claim[] = response;


            //console.log( this.selectedCustomer);

            const indexc = this.selectedCustomer.claimNumber.indexOf("t");
            if (indexc != -1) {
              this.selectedCustomer.claimNumber = this.selectedCustomer.claimNumber.substring(0, indexc);
            }

            for (let claim of claims) {
              if (claim.arcContract) {
                const index = claim.arcContract.indexOf("t");
                if (index != -1) {
                  claim.arcContract = claim.arcContract.substring(0, index);
                }
              }
              //console.log(claim.lockCodeDeacription);
              if (claim.arcContract == this.selectedCustomer.claimNumber) {
                //console.log("lockcodedec before :: " + claim.lockCodeDeacription);
                if (claim.lockCodeDeacription.includes("-")) {
                  var passwordArr = claim.lockCodeDeacription.split("-");
                  claim.lockCodeDeacription = passwordArr[1];
                  //console.log("lockcodedec after :: " + claim.lockCodeDeacription);
                }


                if (claim.country == '' || claim.country == null) {
                  claim.country = 'India';
                }

                if (claim.dispatchCountry == '' || claim.dispatchCountry == null) {
                  claim.dispatchCountry = 'India';
                }

                if (claim.isPhoneLocked == '' || claim.isPhoneLocked == null) {
                  claim.isPhoneLocked = 'No';
                }
                this.selectedClaim = claim;

                // switch tabs if data available
                if(this.selectedClaim.pickupUnit){
                  this.wizardselectedCnt = 2;
                }
                if(this.selectedClaim.dispatchUnit){
                  this.wizardselectedCnt = 3;
                }
                if(this.selectedClaim.colorOfDevice){
                  this.wizardselectedCnt = 4;
				  this.isNextBtnEnabled = false;
                }
                //

                if (updatePayment && this.getPaymentAmount() != null) {
                  this.updateClaimWithPayment();
                }
                if (this.isClaimAddressPresent()) {
                  this.addressSubmitted = true;
                }
              }



            }

            if (this.selectedClaim == null) {
              this.errorMessage = "Claim not found";
            }

            //list document to be process
            this.setDocumentsToBeProcess();
            this.spinner.show();

            //check if payment enabled or not
            //alert(this.isPaymentAllowed() +"##"+ !this.isPaymentPaid());
            //alert((this.isPaymentAllowed() && !this.isPaymentPaid()));
            if (this.isPaymentAllowed() && !this.isPaymentPaid()) {
              this.showPayment = true;
            }


            this.spinner.hide();
          },
          error => {
            this.spinner.hide();
            this.errorMessage = "Something went wrong please try again later.";
          }
        )
    }
  }

  updateClaimWithPayment() {
    //console.log('updateClaimWithPayment');
    this.selectedClaim.serviceTransactionRef = this.paymentId;
    this.selectedClaim.feePaidByCustomer = this.getPaymentAmount().toString();
    const currentDate = new Date();
    this.selectedClaim.paymentDate = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear();
    this.updateClaim(true);


  }

  getPaymentAmount() {
    if (this.selectedCustomer && this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0) {
      for (let payment of this.selectedCustomer.payments) {
        if (payment.paymentAmount) {
          return payment.paymentAmount;
        }
      }
    }
  }

  setDocumentsToBeProcess() {
    if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
      this.nextDocumnets = this.getDocumentsTobUpload();
      this.claimDocument = this.getDocumentsToUpload();
      //console.log("#document list#");
      //console.log(this.claimDocument);
    }
    /*if (this.nextDocumnets && this.nextDocumnets.length > 0) {
      this.tempDocument = this.nextDocumnets[0];
      this.lastDocument = this.nextDocumnets[this.nextDocumnets.length - 1];
    }*/

  }

  showPaymentOption() {
    //alert(!this.tempDocument+"::"+this.isPaymentPaid());
    return !this.tempDocument && !this.isPaymentPaid();
  }

  getDocumentsToUpload() {
    var documents = [];
    if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
      //console.log("#listFromApi#");
      //console.log(this.selectedCustomer.documents);
      for (let doc of this.selectedCustomer.documents) {
        //if ((!doc.url || doc.status == "REJECTED") && doc.documentType != 'HDFC Payment Slip') {
        if (doc.documentType != 'HDFC Payment Slip') {
          documents.push(doc);
        }
      }
    }
    return documents;
  }

  getDocumentsTobUpload() {
    var documnets = [];
    if (this.selectedCustomer && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
      for (let doc of this.selectedCustomer.documents) {
        if (!doc.url || doc.status == "REJECTED") {
          documnets.push(doc);
        }
      }
    }
    return documnets;
  }

  /*onFileChangeUpload(event) {
    console.log("onFileChangeUpload");
    this.errorMessage = null;
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {
        if (this.tempDocument.allowedFileTypes && this.tempDocument.allowedFileTypes.length > 0) {
          var ext=file.name.substring(file.name.lastIndexOf('.')+1);
          console.log(this.tempDocument.allowedFileTypes);
          console.log("ext :: " + ext);  
          if (this.tempDocument.allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
            this.errorMessage = "Allowed file types are " + this.tempDocument.allowedFileTypes.join(", ");
            return;
          }
        }
        
        this.fileUploadData = new FileUploadData();
        const input = new FormData();
        input.append('file', file, file.name);
        input.append("fileName", uuid());
        input.append("functionInstanceName", 'FLOW');
        input.append("entityType", "ClaimCustomer");
        input.append("entityRef", this.selectedCustomer._id);
        this.fileUploadData.formData = input
        
        this.fileUploadData.userFileName = file.name;
        this.fileUploadData.documentName = this.tempDocument.documentName;
        this.tempDocument.userFileName = file.name;
        
      }
    }
    //this.fileInput.nativeElement.value = '';
  }*/

  onFileChangeUpload(event, id) {
    this.tempDocument = this.selectedCustomer.documents[id];
    this.tempDocument.allowedFileTypes = ["jpg", "jpeg", "png", "pdf"];
    //allowed file-size
    //console.log("onFileChangeUpload");
    this.prevErrorMsg = this.errorMessage;
    this.errorMessage = null;
    // this.tempDocument=
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {

        if (this.tempDocument.allowedFileTypes && this.tempDocument.allowedFileTypes.length > 0) {
          var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
          //console.log(this.tempDocument.allowedFileTypes);
          //console.log("ext :: " + ext);  
          if (this.tempDocument.allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
            this.errorMessage = "Allowed file types are " + this.tempDocument.allowedFileTypes.join(", ");
            this.tempDocument = null;
            this.nextDisable = true;
            return;
          }
          else {
            this.nextDisable = false;
          }
        }

        if ((file.size / 1024) >= 1000) {
          this.errorMessage = "Allowed file size is limited to max 500 KB ";
          console.log("file-size-error :: ");
          event.target.value = "";
          return;
        }
        //console.log("file-size-error :: ");  

        this.fileUploadData = new FileUploadData();
        const input = new FormData();
        input.append('file', file, file.name);
        input.append("fileName", uuid());
        input.append("functionInstanceName", 'FLOW');
        input.append("entityType", "ClaimCustomer");
        input.append("entityRef", this.selectedCustomer._id);
        this.fileUploadData.formData = input

        //console.log("checkTempDocument1");
        //console.log(this.tempDocument);

        this.fileUploadData.userFileName = file.name;
        this.fileUploadData.documentName = this.tempDocument.documentName;
        this.fileUploadData.documentType = this.tempDocument.documentType;
        this.tempDocument.userFileName = file.name;

        this.uploadFileDocument.push(this.fileUploadData);
        //this.uploadFileDocument[this.tempDocument.documentName]=this.fileUploadData;
        this.uploadFileTempDocument[this.tempDocument.documentName] = this.tempDocument;

        //console.log("checkTempDocumentR");
        //console.log(this.tempDocument);
        console.log(this.uploadFileTempDocument);

      }
    }
    //this.fileInput.nativeElement.value = '';
  }



  onPaymentSlipFileChange(event) {
    console.log("onPaymentSlipFileChange");
    this.errorMessage = null;
    const allowedFileTypes = ["jpg", "jpeg", "png", "pdf"];
    //allowed file-size
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {

        if (allowedFileTypes && allowedFileTypes.length > 0) {
          var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
          console.log(allowedFileTypes);
          console.log("ext :: " + ext);
          if (allowedFileTypes.indexOf(ext.toLocaleLowerCase()) == -1) {
            this.errorMessage = "Allowed file types are " + allowedFileTypes.join(", ");
            return;
          }
        }

        if ((file.size / 1024) > 500) {
          this.errorMessage = "Allowed file size is limited to max 500 KB ";
          event.target.value = "";
          return;
        }

        this.fileUploadData = new FileUploadData();
        const input = new FormData();
        input.append('file', file, file.name);
        input.append("fileName", uuid());
        input.append("functionInstanceName", 'FLOW');
        input.append("entityType", "ClaimCustomer");
        input.append("entityRef", this.selectedCustomer._id);
        this.fileUploadData.formData = input

        this.fileUploadData.userFileName = file.name;
        this.fileUploadData.documentName = this.paymentDocument.documentName;
        this.paymentDocument.userFileName = file.name;

      }
    }
  }

  proccessPreviousDocumnet() {
    this.errorMessage = null;
    if (this.previousDocuments && this.previousDocuments.length > 0) {
      this.nextDocumnets.push(JSON.parse(JSON.stringify(this.tempDocument)));
      this.tempDocument = this.previousDocuments[this.previousDocuments.length - 1];
      //this.addDocumnetToSubmitDocumnets(this.tempDocument);
      const index = this.previousDocuments.indexOf(this.tempDocument);
      if (index != -1) {
        this.previousDocuments.splice(index, 1);
      }
    }
  }

  processNextdocument() {
    this.errorMessage = null;
    if (this.tempDocument && this.nextDocumnets.length > 0) {
      this.replaceCustomerDocs(this.tempDocument);
      this.uploadDocument(1);
    }
  }

  replaceCustomerDocs(processDoc: Documents) {
    if (processDoc && this.selectedCustomer && this.selectedCustomer.documents) {
      var docTobeRemove: Documents = null;
      for (let doc of this.selectedCustomer.documents) {
        if (doc.documentName && processDoc.documentName && doc.documentName == processDoc.documentName) {
          docTobeRemove = doc;
        }
      }
      if (docTobeRemove) {
        var index = this.selectedCustomer.documents.indexOf(docTobeRemove);
        if (index != -1) {
          this.selectedCustomer.documents.splice(index, 1);
          this.selectedCustomer.documents.push(processDoc);
        }
      }
      console.log("replaceCustomerDocs");
      console.log(this.selectedCustomer.documents);
    }
  }

  displayNext() {
    this.previousDocuments.push(JSON.parse(JSON.stringify(this.tempDocument)));
    this.tempDocument = this.nextDocumnets[this.nextDocumnets.length - 1];
    const index = this.nextDocumnets.indexOf(this.tempDocument);
    if (index != -1) {
      this.nextDocumnets.splice(index, 1);
    }
    if (this.nextDocumnets.length == 0) {
      this.tempDocument = null;
      if (this.isPaymnetAllow() && !this.isPaymentPaid()) {
        this.showPayment = true;
      }
      else {
        if (!this.isFlowNeedToBeUpdate()) {
          this.updateCustomer();
        }
        //alert("success");
      }
    }

  }

  isLastDocument() {
    if (this.nextDocumnets && this.nextDocumnets.length > 0 && this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0 && this.lastDocument) {
      //console.log(this.lastDocument.documentName);
      //console.log(this.tempDocument.documentName);
      //console.log(this.lastDocument.documentName == this.tempDocument.documentName);
      return this.lastDocument.documentName == this.tempDocument.documentName;
    }
    return false;
  }

  isPaymentPaid() {
    if (!this.selectedCustomer.payments || this.selectedCustomer.payments.length == 0) {
      return true;
    }
    return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentStatus.toUpperCase() == "PAID";
  }

  isPaymnetAllow() {
    return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentAmount;
  }

  isPaymentAllowed() {
    return this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0 && this.selectedCustomer.payments[0].paymentAmount;
  }


  uploadPaymentSlip() {
    if (this.fileUploadData) {
      this.spinner.show();
      this.customerService.uploadDocument(this.fileUploadData.formData)
        .subscribe(
          response => {
            //console.log("upload payment slip");
            //console.log(response);
            this.paymentDocument.url = response.url;
            this.paymentDocument.downloadFileUrl = response.downloadFileUrl;
            this.paymentDocument.fileName = response.fileName;
            this.paymentDocument.fullDataUrl = response.fullDataUrl;
            this.replaceDocumentByDocumentType(this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP);
            for (let payment of this.selectedCustomer.payments) {
              if (payment) {
                payment.paymentChannel = this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP
                payment.paymentStatus = "PAID"
              }
            }
            this.updateCustomer();

            //show payment success modal
            this.showPaymentSuccessModal();

          },
          error => {
            this.errorMessage = "Something went wrong. Please try again"
            this.fileUploadData = null;
            this.spinner.hide();
          }
        )
    }
  }

  replaceDocumentByDocumentType(documentType: string) {
    if (documentType && this.selectedCustomer) {
      if (!this.selectedCustomer.documents) {
        this.selectedCustomer.documents = [];
      }
      var existingDoc: Documents = null;
      for (let doc of this.selectedCustomer.documents) {
        if (doc && doc.documentType == this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP) {
          existingDoc = doc;
        }
      }
      if (existingDoc != null) {
        const index = this.selectedCustomer.documents.indexOf(existingDoc);
        if (index != -1) {
          this.selectedCustomer.documents.splice(index, 1);
          this.selectedCustomer.documents.splice(index, 0, this.paymentDocument);
        }

      }
      else {
        this.selectedCustomer.documents.push(this.paymentDocument);
      }

    }
  }

  uploadDocument2(getType) {
    console.log("fileUploadData");
    console.log(this.fileUploadData);
    if (this.fileUploadData) {
      this.customerService.uploadDocument(this.fileUploadData.formData)
        .subscribe(
          response => {
            console.log("file uploaded successfully");
            console.log(response);
            this.tempDocument.url = response.url;
            this.tempDocument.downloadFileUrl = response.downloadFileUrl;
            this.tempDocument.fileName = response.fileName;
            this.tempDocument.fullDataUrl = response.fullDataUrl;
            this.tempDocument.fullFileUrl = response.fullFileUrl;
            this.updateCustomer();
            this.fileUploadData = null;
            if (getType == 2) {
              this.showDocumentSuccessModal();
            }
          },
          error => {
            console.log('error in file upload');
            console.log(error);
            this.errorMessage = "Something went wrong. Please try again"
            this.fileUploadData = null;
            this.spinner.hide();
          }
        )
    } else {
      if (getType == 2) {
        this.showDocumentSuccessModal();
      }
    }
    /* else if (this.tempDocument.mandatory && !this.tempDocument.url) {
       this.errorMessage = this.tempDocument.documentType + " is mandatory.";
       this.spinner.hide();
     }*/
    /*else {
      this.displayNext();
    }*/
  }


  uploadDocument(getType) {



    if (this.uploadFileDocument.length > 0) {
      for (let doc of this.uploadFileDocument) {
        if (doc.userFileName == null) {
          this.errorMessage = "Upload " + doc.documentType
          return;
        }
      }
      this.spinner.show();
      var numberOfdocs = this.uploadFileDocument.length;
      this.uploadFileDocument.forEach(subDoc => {
        //console.log(subDoc);
        this.customerService.uploadDocument(subDoc.formData)
          .subscribe(response => {
            //console.log("file uploaded successfully");
            //console.log(response);
            //console.log("file uploaded successfully tempDoc");
            //console.log(this.tempDocument);

            this.tempDocument = this.uploadFileTempDocument[subDoc.documentName];
            //console.log("file uploaded successfully tempDocUpdated");
            //console.log(this.tempDocument);
            this.tempDocument.url = response.url;
            this.tempDocument.downloadFileUrl = response.downloadFileUrl;
            this.tempDocument.fileName = response.fileName;
            this.tempDocument.fullDataUrl = response.fullDataUrl;
            this.tempDocument.fullFileUrl = response.fullFileUrl;
            this.fileUploadData = null;

            //update customer 
            numberOfdocs--;
            if (numberOfdocs == 0) {
              this.spinner.hide();
              this.updateCustomer();
            }



          },
            error => {
              console.log('error in file upload');
              console.log(error);
              this.errorMessage = "Something went wrong. Please try again"
              this.fileUploadData = null;
              this.nextDisable = true;
              this.showPayment = false;
              this.spinner.hide();
            })
      });

      if (getType == 2) {
        this.showDocumentSuccessModal();
      }
      //this.spinner.hide();
      //update customer  - all at time
      //	this.updateCustomer();
      this.uploadFileDocument = [];

    } else {
      if (getType == 2) {
        this.showDocumentSuccessModal();
      }
    }
  }


  updateNextAndPreviousDocs() {
    if (this.previousDocuments && this.previousDocuments.length > 0) {
      for (let index = 0; index < this.previousDocuments.length; index++) {
        for (let doc of this.selectedCustomer.documents) {
          if (doc.documentName && this.previousDocuments[index].documentName && doc.documentName && this.previousDocuments[index].documentName) {
            this.previousDocuments.splice(index, 0, doc);
          }
        }
      }
    }
    if (this.nextDocumnets && this.nextDocumnets.length > 0) {
      for (let index = 0; index < this.nextDocumnets.length; index++) {
        for (let doc of this.selectedCustomer.documents) {
          if (doc.documentName && this.nextDocumnets[index].documentName && doc.documentName && this.nextDocumnets[index].documentName) {
            this.nextDocumnets.splice(index, 0, doc);
          }
        }
      }
    }
  }

  isFlowNeedToBeUpdate() {
    // && this.selectedCustomer.documents
    console.log('isFlowNeedToBeUpdate',this.selectedCustomer)
    if (this.selectedCustomer) {
      // for (let doc of this.selectedCustomer.documents) {
      //   if (doc.mandatory && !doc.url) {
      //     return false;
      //   }
      // }
      if (this.selectedCustomer.payments && this.selectedCustomer.payments.length > 0) {
        for (let paymnet of this.selectedCustomer.payments) {
          if (!paymnet.paymentAmount) {
            this.selectedCustomer.statusCd = "IDLE";
            return true;
          }
          if (paymnet.paymentStatus && paymnet.paymentStatus.toUpperCase() == "PAID") {
            this.selectedCustomer.statusCd = "IDLE";
            return true;
          }
        }
      }
      else {
        this.selectedCustomer.statusCd = "IDLE";
        return true;
      }
    }
    return false
  }

  updateCustomer(paymentSuccess?: boolean) {
    this.spinner.show();
    this.customerService.updateClaimCustomer(this.selectedCustomer, this.isFlowNeedToBeUpdate())
      .subscribe(
        response => {
          //console.log("customerupdated");
          //console.log(response);
          this.spinner.hide();
          if (response && response.success && response.result) {
            this.selectedCustomer = response.result
            //this.updateNextAndPreviousDocs();
            //console.log(this.tempDocument);
            if (this.tempDocument != null) {
              this.displayNext();
            }
            if (paymentSuccess) {
              this.getClaimsByImei(true);
              //this.showPaymentSuccessModal();
            }
          }
          else if (response && !response.success) {
            if (response.error) {
              if (typeof response.error == "string") {
                this.errorMessage = response.error;
              }
              if (response.error && response.error.message) {
                this.errorMessage = response.error.message;
              }
            }
            else {
              this.errorMessage = "unable to update customer";
            }
          }
          
        },
        error => {
          console.log("error");
          console.log(error);
        }
      )
  }



  addDocumnetToSubmitDocumnets(doc: Documents) {
    if (doc) {
      for (let subDoc of this.selectedCustomer.documents) {
        if (subDoc.documentName == doc.documentName) {
          return;
        }
      }
      this.submitDocuments.push(doc);
    }
  }

  replaceDocument() {
    if (this.tempDocument && this.submitDocuments) {
      const index = this.submitDocuments.indexOf(this.tempDocument);
      if (index != -1) {
        this.previousDocuments.push(this.tempDocument);
        this.submitDocuments.splice(index, 1);
      }
    }
  }

  onSelectPickupSameAsDispatch(value) {
    console.log("onSelectPickupSameAsDispatch");
    if (this.isPickupAddressSameAsDispatchAddress) {
      this.selectedClaim.dispatchArea = this.selectedClaim.pickupArea;
      this.selectedClaim.dispatchUnit = this.selectedClaim.pickupUnit;
      this.selectedClaim.dispatchCity = this.selectedClaim.pickupCity;
      this.selectedClaim.dispatchCountry = this.selectedClaim.country;
      this.selectedClaim.dispatchState = this.selectedClaim.pickupState;
      this.selectedClaim.dispatchPinCode = this.selectedClaim.pinCode;
    }
  }

  onClaimAddressSubmit() {
    this.spinner.show();
    this.updateClaim();

  }

  updateClaim(paymentDone?: boolean) {
    this.customerService.updateClaim(this.selectedCustomer.imei, this.selectedClaim)
      .subscribe(
        response => {
          //console.log("updateclaim");
          //console.log(response);
          this.selectedCustomer.pickupAddress = this.selectedClaim.pickupArea;
          this.selectedCustomer.pickupUnit = this.selectedClaim.pickupUnit;
          this.selectedCustomer.pickupCity = this.selectedClaim.pickupCity;
          this.selectedCustomer.pickupState = this.selectedClaim.pickupState;
          this.selectedCustomer.pickupPincode = this.selectedClaim.pickupPinCode;
          this.selectedCustomer.dispatchAddress = this.selectedClaim.dispatchArea;
          this.selectedCustomer.dispatchUnit = this.selectedClaim.dispatchUnit;
          this.selectedCustomer.dispatchCity = this.selectedClaim.dispatchCity;
          this.selectedCustomer.dispatchState = this.selectedClaim.dispatchState;
          this.selectedCustomer.dispatchPincode = this.selectedClaim.dispatchPinCode;
          this.selectedCustomer.deviceColor = this.selectedClaim.colorOfDevice;
          this.selectedCustomer.deviceMemory = this.selectedClaim.memorycapacity;
          this.selectedCustomer.deviceLock = this.selectedClaim.lockCodeDeacription;
          this.selectedCustomer.deviceLockCode = this.selectedClaim.lockCode;
          console.log("payment done",paymentDone);
          if (paymentDone) {
            setTimeout(() => {
              this.spinner.hide();
              this.showPaymentSuccessModal();
            }, 500);
          }
          else {
            this.updateCustomer();
          }
          if (response.success) {
            this.addressSubmitted = true;
          }
          if (!response.success && response.error && response.error.message) {
            this.errorMessage = response.error.message;
          }
          this.spinner.hide();
        },
        error => {
          console.log("update claim error");
          console.log(error);
          this.spinner.hide();
        }
      )
  }

  onPaymentMethodSelection(paymentMethod: string) {
    if (paymentMethod && paymentMethod == "PAY_AT_HDFC") {
      this.paymentDocument = new Documents();
      this.paymentDocument.documentName = uuid();
      this.paymentDocument.documentType = this.DOCUMENT_TYPE_HDFC_PAYMENT_SLIP;
      this.paymentDocument.description = "HDFC payment slip"
      this.paymentDocument.mandatory = true;

    }
    else {
      this.paymentDocument = null;
    }
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered'
    });
  }

  validateForm() {
    var formTab, formTabInput, i, valid = true;
    this.errorMessage = '';

    formTab = $('.tab-pane');
    {
      formTabInput = formTab[0].getElementsByClassName("required-input");

      for (i = 0; i < formTabInput.length; i++) {

        if (formTabInput[i].value == "") {
          if (formTabInput[i].getAttribute('dataType') == 'File') {
            this.errorMessage = formTabInput[i].name + " Input is Mandatory."
          } else {
            this.errorMessage = formTabInput[i].getAttribute('data_field') + " Input is Mandatory."
            formTabInput[i].focus();
          }
          valid = false;
          return valid;
        } else {
          if (formTabInput[i].getAttribute('dataType') == 'Pincode') {
            var regex = /^[1-9][0-9]{5}$/;
            if (!regex.test(formTabInput[i].value)) {
              this.errorMessage = formTabInput[i].name + " Input is Invalid."
              formTabInput[i].focus();
              valid = false;
              return valid;
            }
          }
        }
      }
    }
    return valid; // return the valid status
  }


  nextPrev(getCount) {
    if (getCount == 1 && !this.validateForm()) return false;

    if (getCount == 1)// call the api only on next btn
    {
      if (this.wizardselectedCnt > 0 && this.wizardselectedCnt < 4) {
        this.onClaimAddressSubmit();
      }
		/* document verification commented
	  else if (this.wizardselectedCnt == 4) {

        if (this.selectedCustomer.documents && this.selectedCustomer.documents.length > 0) {
          for (let doc of this.selectedCustomer.documents) {
            if (doc.url == null && (!doc.status || doc.status == "PENDING" || doc.status == "REJECTED")) {
              if (this.uploadFileDocument.length == 0) {
                this.errorMessage = "Document " + doc.documentType + " is mandatory";
                return;
              }
              else {
                var docPresent = false;
                for (let uploadDoc of this.uploadFileDocument) {
                  if (uploadDoc && uploadDoc.documentName == doc.documentName) {
                    docPresent = true;
                  }
                }
                if (!docPresent) {
                  this.errorMessage = "Document " + doc.documentType + " is mandatory";
                  return;
                }
              }

            }
          }
        }

        this.uploadDocument(1);
      }
	  */
    }

    this.wizardselectedCnt = this.wizardselectedCnt + getCount;

    this.isSubmitBtnEnabled = false;
    this.isDocumentSubmitBtnEnabled = false;
    this.isPreviousBtnEnabled = true;
    this.isNextBtnEnabled = true;

    if (this.wizardselectedCnt < 1) {
      this.isPreviousBtnEnabled = false;
    } else if (this.wizardselectedCnt == 1) {
      this.isPreviousBtnEnabled = false;
    } else if (this.wizardselectedCnt == 4) {
      if (!this.showPayment) {
        this.isDocumentSubmitBtnEnabled = true;
      }
      this.isNextBtnEnabled = false;
    } else if (this.wizardselectedCnt == 5) {
      this.isNextBtnEnabled = false;
      this.isSubmitBtnEnabled = true;
    }
  }

  nextLast(getType) {
    if (!this.validateForm()) return false;
    if (getType == 1) {
      this.uploadDocument(2);
    } else if (getType == 2) {
      this.uploadPaymentSlip();
    }
  }

  onSelectPin(getType) {
    // alert('ty'+getType)
  }

  resetModal() {
    this.wizardselectedCnt = 1;
  }

  //show payment success modal
  showPaymentSuccessModal() {
    this.showModal = true;
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  //show payment failed modal
  showPaymentFailedModal() {
    this.showFailedModal = true;
  }

  //show document success modal
  showDocumentSuccessModal() {
    this.showDocumentModal = true;
  }

  logoutCustomer() {
    this.universalUser.removeUser();
  }

  homeCustomer() {
    this.router.navigate(['/#/pg/customer']);
  }


}