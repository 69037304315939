var Customer = /** @class */ (function () {
    function Customer() {
        this.status = null;
        this.customerFullName = null;
        this.policyId = null;
        this.policyType = null;
        this.entityId = null;
        this.updatedTime = null;
        this.documents = [];
        this.claimNumber = null;
        this.customerFirstName = null;
        this.companyId = null;
        this.phoneNumber = null;
        this.claimCreatedBy = null;
        this.createdTime = null;
        this.claimCreatedFrom = null;
        this._id = null;
        this.clientName = null;
        this.payments = [];
        this.imei = null;
        this.statusCd = null;
        this.dispatchState = null;
        this.deviceLockCode = null;
        this.dispatchPincode = null;
        this.docChaserCounter = 0;
        this.pickupAddress = null;
        this.dispatchCity = null;
        this.pickupUnit = null;
        this.pickupState = null;
        this.pickupCity = null;
        this.dispatchAddress = null;
        this.deviceMemory = null;
        this.deviceLock = null;
        this.dispatchUnit = null;
        this.deviceColor = null;
        this.pickupPincode = null;
        this.status = null;
        this.customerFullName = null;
        this.customerFirstName = null;
        this.policyId = null;
        this.updatedTime = null;
        this.documents = [];
        this.claimNumber = null;
        this.companyId = null;
        this.phoneNumber = null;
        this.claimCreatedBy = null;
        this.createdTime = null;
        this.claimCreatedFrom = null;
        this._id = null;
        this.clientName = null;
        this.payments = [];
        this.imei = null;
        this.statusCd = null;
    }
    return Customer;
}());
export { Customer };
